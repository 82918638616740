<!-- <div class="w-176 text-center rounded-2.5xl"> -->
<div class="w-full text-center rounded-2.5xl">
  <!-- Header -->
  <div
    class="border-b border-gray-300"
    [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <h1 [ngClass]="{ 'text-xl': isMobile }">
      <span class="flex flex-col items-center">
        <img
          [src]="configurationService.configuration.logo"
          alt="Club logo"
          class="h-20 mb-2" />
        <span class="text-red">WELCOME!</span>
      </span>
    </h1>
  </div>
  <div [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <!-- <h4 class="font-normal">{{ content }}</h4> -->
    <h4 class="font-normal">
      Would you like to view a quick tutorial before purchasing tickets?
    </h4>
    <div class="flex justify-center" [ngClass]="isMobile ? 'mt-5' : 'mt-12'">
      <button
        (click)="close()"
        class="btn-main-reverse"
        [ngClass]="isMobile ? 'mr-3' : 'mr-6'">
        No
      </button>
      <button (click)="accept()" class="btn-main">Yes</button>
    </div>
    <div *ngIf="isMobile" class="small info-help mt-3">
      <span
        >For questions or assistance,<br />please call
        <b>{{ configurationService.configuration.phone }}</b
        ><br />or email
        <b>{{ configurationService.configuration.email }}</b></span
      >
    </div>
  </div>
</div>
