import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import OktaAuth from '@okta/okta-auth-js';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ModalsService } from '../modals/modals.service';
import { ConnectionService } from './connection.service';

@Injectable({
  providedIn: 'root',
})
export class PrivateSellingService {
  public routeToNavigateBack: string = 'seat-selection';
  public ssoRoute: string = `${window.location.origin}/login`;
  public isEnabled = signal(false);
  public isLoginAttempted = signal(false);
  public isUserInWhitelist = signal(false);

  public readonly QUERY_PARAMS = {
    EVENT: 'event',
    STATE: 'state',
    IS_UNAUTHORIZED: 'isUnauthorized',
    IS_SIGN_IN_COMPLETE: 'isSignInComplete',
  };

  private router = inject(Router);
  private modalsService = inject(ModalsService);
  private connectionService = inject(ConnectionService);
  /**
   * Authenticates the user through SSO, retrieves their profile, and checks their whitelist status.
   * @returns Resolves when the authentication process completes.
   */
  public async authenticate() {
    const queryParams = new URLSearchParams(window.location.search);
    const stateParam = queryParams.get(this.QUERY_PARAMS.STATE) || '';
    const destination = `${window.location.href}${stateParam}`;

    const client = this.initializeSsoClient();
    const isAuthenticated = await this.authenticateWithSso(client, destination);
    if (!isAuthenticated) return null;

    const user = await client.getUser();
    await this.checkPatronIdInWhitelist(user.email);
  }
  /**
   * Initializes and configures the Okta SSO client.
   * @returns An instance of the OktaAuth client.
   */
  public initializeSsoClient(): OktaAuth {
    return new OktaAuth({
      issuer: environment.oktaData.issuer,
      clientId: environment.oktaData.clientId,
      redirectUri: this.ssoRoute,
    });
  }

  /**
   * Deletes the sign-in parameter, sets appropriate states, and opens a forbidden modal.
   */
  public handleStateBackingFromMlb = (): void => {
    this.isEnabled.set(true);
    this.isLoginAttempted.set(true);
    this.isUserInWhitelist.set(false);
    this.modalsService.openForbiddenEventModal();
  };

  /**
   * Authenticates the user via SSO by redirecting them as needed.
   * @param authClient - The OktaAuth client used for authentication.
   * @param redirectDestination - The destination URL for redirection post-authentication.
   * @returns Resolves to `true` if authentication is successful.
   */
  private async authenticateWithSso(
    authClient: OktaAuth,
    redirectDestination: string
  ): Promise<boolean> {
    let isAuthenticated = false;
    const queryParams = new URLSearchParams(window.location.search);
    const { IS_UNAUTHORIZED, IS_SIGN_IN_COMPLETE } = this.QUERY_PARAMS;

    if (!queryParams.has(IS_SIGN_IN_COMPLETE)) {
      const redirectUrl = new URL(redirectDestination);
      redirectUrl.searchParams.append(IS_SIGN_IN_COMPLETE, 'true');

      authClient.tokenManager.clear();
      authClient.setOriginalUri(redirectUrl.toString());
      await authClient.signInWithRedirect({ prompt: 'none' });
    } else if (queryParams.has(IS_UNAUTHORIZED)) {
      await authClient.signInWithRedirect();
    } else {
      isAuthenticated = true;
    }

    return isAuthenticated;
  }

  /**
   * Checks if the user's email is in the whitelist.
   * @param email - The user's email to verify.
   * @returns Resolves when the whitelist check is complete.
   */
  private async checkPatronIdInWhitelist(email: string): Promise<void> {
    try {
      await firstValueFrom(
        this.connectionService.getPatronIdFromWhitelist(email)
      );
      this.isUserInWhitelist.set(true);
    } catch {
      this.isUserInWhitelist.set(false);
      this.modalsService.openForbiddenEventModal();
    } finally {
      this.isLoginAttempted.set(true);
      this.navigateToSeatSelection();
    }
  }

  /**
   * Navigates the user to the seat selection route, clearing specific query parameters.
   */
  private navigateToSeatSelection() {
    this.router.navigate([this.routeToNavigateBack], {
      queryParams: {
        [this.QUERY_PARAMS.IS_SIGN_IN_COMPLETE]: undefined,
        [this.QUERY_PARAMS.EVENT]: this.connectionService.eventId.toString(),
      },
    });
  }
}
