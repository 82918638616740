import { inject, isDevMode } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

/**
 * Guard which allows navigating to the events landing page exclusivelly
 * when the project is run locally.
 */
export const eventLandingGuard: CanActivateFn = () => {
  if (isDevMode()) {
    return true;
  }

  const router = inject(Router);
  router.navigate(['seat-selection']);
  return false;
};
