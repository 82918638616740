<div class="w-full text-center rounded-2.5xl">
  <div class="border-b border-gray-300 text-center px-12 md:px-24 py-12">
    <h1>Ups...</h1>
  </div>
  <div class="px-12 md:px-24 pt-12 pb-6">
    <h4 class="font-normal">This event is not available for sale.</h4>
  </div>
  <div
    class="flex justify-center px-12 md:px-24 pb-12 pt-6 text-center break-words">
    <span
      >For questions or assistance,<br />please call
      <b>{{ configurationService.configuration.phone }}</b
      ><br />or email
      <b>{{ configurationService.configuration.email }}.</b></span
    >
  </div>
</div>
