import { Component, Input, OnInit } from '@angular/core';
import { SaleEvent, Upsell } from 'src/app/shared/models';
import {
  AvailabilityService,
  CartService,
  UtilitiesService,
} from 'src/app/shared/services';
import { UpsellsService } from './upsells.service';

@Component({
  selector: 'app-upsells',
  templateUrl: './upsells.component.html',
  styleUrls: ['./upsells.component.scss'],
})
export class UpsellsComponent implements OnInit {
  isMobile: boolean;

  // @Input() currentEvent: SaleEvent;

  get upsells(): Upsell[] {
    return this.upsellsService.upsells;
  }
  get reachingUpsell(): Upsell | null {
    return this.upsellsService.reachingUpsell;
  }
  get acquiredUpsell(): Upsell | null {
    return this.upsellsService.acquiredUpsell;
  }
  get availableUpsells(): Upsell[] {
    return this.upsellsService.availableUpsells;
  }
  get acquiredUpsells(): Upsell[] {
    return this.upsellsService.acquiredUpsells;
  }

  // acquiredUpsellsTotal: number;

  openUpsellsBox: boolean;
  totalCartAndFiltersSeats: number;
  // Cart seats
  cartSeats: number;

  constructor(
    private upsellsService: UpsellsService,
    private cartService: CartService,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.openUpsellsBox = false;

    //* UPSELLS
    // Subcribe to cart total seats changes to update Upsells benefits list
    this.cartService.selectedSeats$.subscribe({
      next: response => {
        // console.log('Selected Cart Seats: ', response);
        const totalCartSeats = Object.keys(response).length;
        this.upsellsService.checkCartSeatsForUpsellsList(totalCartSeats);
        // Check for notifications but only with cart seats (?)
        this.upsellsService.checkTotalSeatsForUpsellsMessages(totalCartSeats);
      },
      error: error => console.error(error),
    });

    // Cada vez que cambia el totalCartAndFiltersSeats, se llama al checkTotalSeatsForUpsells(totalCartSeats) del upsellsService para actualizar la lista de Benefits.
    this.upsellsService.totalCartAndFiltersSeats$.subscribe({
      next: response => {
        this.totalCartAndFiltersSeats = response;
        // console.log('Total Seats: ', this.totalCartAndFiltersSeats);
        this.upsellsService.checkTotalSeatsForUpsellsMessages(
          this.totalCartAndFiltersSeats
        );
      },
    });
  }

  toggleGiftsBox() {
    this.openUpsellsBox = !this.openUpsellsBox;
  }
}
