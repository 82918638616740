import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services';
import { SaleEvent } from '../../shared/models';

@Component({
  selector: 'app-sale-event',
  templateUrl: './sale-event.component.html',
  styleUrls: ['./sale-event.component.scss'],
})
export class SaleEventComponent implements OnInit {
  @Input() event: SaleEvent;
  @Output() notifySelected: EventEmitter<number> = new EventEmitter();
  isMobile: boolean = false;

  constructor(private utilitiesService: UtilitiesService) {}

  ngOnInit(): void {
    this.isMobile = this.utilitiesService.isMobile;
  }

  notifyParent(pvEventId: number) {
    this.notifySelected.emit(pvEventId);
  }
}
