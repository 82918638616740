<div class="w-full rounded-2.5xl">
  <!-- Header -->
  <div
    class="border-b border-gray-300 text-center"
    [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <h1 [ngClass]="{ 'text-xl': isMobile }" [innerHTML]="title"></h1>
    <h3>Section: {{ seatSection }} Row: {{ seatRow }} Seat: {{ seat }}</h3>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <h4>Information about the seat:</h4>
    <ul style="list-style-type: disc; padding-left: 20px">
      <li *ngFor="let item of definitions">
        {{ item.description }}
      </li>
      @if (isAda) {
        <li>The seat is ADA.</li>
      }
    </ul>
    <div class="flex justify-center" [ngClass]="isMobile ? 'mt-6' : 'mt-12'">
      <button
        (click)="close()"
        class="btn-main"
        [ngClass]="isMobile ? 'mr-3' : 'mr-6'">
        Close
      </button>
    </div>
  </div>
</div>
