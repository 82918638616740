import { MapViewerNode } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AvailabilityService, UtilitiesService } from 'src/app/shared/services';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { BestAvailableService } from '../services/best-available.service';
import { DvmService } from '../services/dvm.service';
import { SeatManagementService } from '../services/seat-management.service';

@Injectable()
export class ClickHandler {
  constructor(
    private dvmService: DvmService,
    private seatManagementService: SeatManagementService,
    private availabilityService: AvailabilityService,
    private bestAvailableService: BestAvailableService,
    private configurationService: ConfigurationService,
    private utilitiesService: UtilitiesService,
    private modalsService: ModalsService
  ) {
    // CLICK subscribe
    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        const subscription = viewer.subscribe('click', this.handle.bind(this));
        this.dvmService.viewerSubscriptions.push(subscription);
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Viewer.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      },
    });
    this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  private handle(obj) {
    let viewer = obj.instance;
    let node: MapViewerNode = obj.nodes[0];

    if (node) {
      const gaSectorsIdArray =
        this.configurationService.configuration.dvmData.gaSectorsIdArray;

      if (gaSectorsIdArray.includes(node.id)) {
        if (node.state !== 'available') return;

        const publicDescription =
          this.availabilityService?.sectionsAvailability?.[node.id]
            ?.publicDescription ?? null;

        const modalData = {
          title: 'General Admission Section',
          subtitle: publicDescription,
          content:
            'Please, select the number of seats to add from the General Admission section.',
          closeBtnName: 'CANCEL',
          acceptBtnName: 'CONFIRM',
          isAda: false,
          acceptFunction: () => {},
        };
        this.bestAvailableService.lastSelectedSection = node.id;
        this.modalsService.openGaModal(modalData);
        return;
      }

      const gaAdaIdArray =
        this.configurationService.configuration.dvmData.gaAdaIdArray;

      if (gaAdaIdArray.includes(node.id)) {
        if (node.state !== 'available') return;

        const publicDescription =
          this.availabilityService?.sectionsAvailability?.[node.id]
            ?.publicDescription ?? null;

        const modalData = {
          title: 'General Admission Section (ADA)',
          subtitle: publicDescription,
          content:
            'Please, select the number of ADA locations to add from the General Admission section.',
          closeBtnName: 'CANCEL',
          acceptBtnName: 'CONFIRM',
          isAda: true,
          acceptFunction: () => {},
        };
        this.bestAvailableService.lastSelectedSection = node.id;
        this.modalsService.openGaModal(modalData);
        return;
      }

      // If sector is not ga, go with the usual BA flow
      if (node.type === 'section' && !this.utilitiesService.isMobile) {
        if (node.state === 'available') {
          // this.dvmService.loadMap(viewer, node.id);
          this.bestAvailableService.lastSelectedSection = node.id;
          this.bestAvailableService.selectSectionForBA(node.id);
        } else if (node.state === 'selected') {
          // console.log('Node Section Selected', node);
          this.bestAvailableService.unselectSectionForBA(node.id);
        }
      }

      // //* CLICKING SEAT NODE
      if (node.type === 'seat') {
        if (node.state === 'available') {
          // console.log('Node Seat Available', node);
          this.seatManagementService.selectAvailableSeats([
            { id: node.id, original_id: node.original_id },
          ]);
        } else if (node.state === 'selected') {
          // console.log('Node Seat Selected', node);
          this.seatManagementService.unselectSelectedSeats([
            { id: node.id, original_id: node.original_id },
          ]);
        }
      }
    }
  }
}
