import { z } from 'zod';
import { Entity, RGBColor } from '../models/configuration.model';
import { BostonRedSoxConfig } from './boston-red-sox.conf';
import { ChicagoCubsConfig } from './chicago-cubs.conf';
import { ChicagoCubsSpring } from './cubs-spring.conf';
import { DefaultConfig } from './default.conf';
import { HoustonAstrosConfig } from './houston-astros.conf';
import { MiamiMarlinsConfig } from './miami-marlins.conf';
import { PittsburghPiratesConfig } from './pittsburgh-pirates.conf';
import { WashingtonNationalsConfig } from './washington-nationals.conf';

export const configurationSchema = z.object({
  clientName: z.string(),
  appName: z.string(),
  logo: z.string(),
  analytics: z.string(),
  favicon: z.string(),
  email: z.string().email(),
  phone: z.string(),
  paymentMethod: z.string(),
  theme: z.object({
    'default-color': z.custom<RGBColor>(),
    'main-color': z.custom<RGBColor>(),
    'secondary-color': z.custom<RGBColor>(),
    'tertiary-color': z.custom<RGBColor>(),
    red: z.custom<RGBColor>(),
    success: z.custom<RGBColor>(),
    'success-off-white': z.custom<RGBColor>(),
  }),
  dvmData: z.object({
    venueId: z.string(),
    mapId: z.string(),
    miniMapId: z.string(),
  }),
  dvmStyles: z.object({
    'seat-available-none-fill': z.custom<RGBColor>(),
    'seat-available-none-stroke': z.custom<RGBColor>(),
    'seat-available-disabled-fill': z.custom<RGBColor>(),
    'seat-available-disabled-stroke': z.custom<RGBColor>(),
    'seat-unavailable-none': z.custom<RGBColor>(),
    'seat-unavailable-pending': z.custom<RGBColor>(),
    'section-available-normal-fill': z.custom<RGBColor>(),
    'section-available-normal-stroke': z.custom<RGBColor>(),
    'section-available-normal-group1-fill': z.custom<RGBColor>(),
    'section-available-normal-group1-stroke': z.custom<RGBColor>(),
    'section-available-hover-fill': z.custom<RGBColor>(),
    'section-available-hover-stroke': z.custom<RGBColor>(),
    'section-selected-normal-fill': z.custom<RGBColor>(),
    'section-selected-normal-stroke': z.custom<RGBColor>(),
    'section-selected-hover-stroke': z.custom<RGBColor>(),
  }),
});

export const configurationData: Entity = {
  default: DefaultConfig,
  chicagocubs: ChicagoCubsConfig,
  redsox: BostonRedSoxConfig,
  miamimarlins: MiamiMarlinsConfig,
  houstonastros: HoustonAstrosConfig,
  pittsburghpirates: PittsburghPiratesConfig,
  chicagocubsspring: ChicagoCubsSpring,
  washingtonnationals: WashingtonNationalsConfig,
};
