import { Component, inject, OnInit } from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';
import { PrivateSellingService } from '../../../shared/services/private-selling.service';

@Component({
  selector: 'app-sso-page',
  templateUrl: './sso-page.component.html',
  styleUrl: './sso-page.component.scss',
  styles: [':host { display: contents; }'],
})
export class SsoPageComponent implements OnInit {
  private privateSellingService = inject(PrivateSellingService);

  async ngOnInit() {
    const authClient = this.privateSellingService.initializeSsoClient();
    const failureDestinationUrl = this.getFailureDestinationUrl(authClient);

    if (!authClient.isLoginRedirect()) {
      return window.location.replace(failureDestinationUrl);
    }

    try {
      await authClient.handleLoginRedirect();
    } catch (error) {
      console.error(error);
      await authClient.signInWithRedirect();
    }
  }

  private getFailureDestinationUrl(authClient: OktaAuth) {
    const failureDestinationUrl = new URL(authClient.getOriginalUri());
    failureDestinationUrl.searchParams.append(
      this.privateSellingService.QUERY_PARAMS.IS_UNAUTHORIZED,
      'true'
    );
    return failureDestinationUrl;
  }
}
