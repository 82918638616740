import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { CheckoutComponent } from './checkout/checkout.component';
import { EventLandingComponent } from './event-landing/event-landing.component';
import { eventLandingGuard } from './event-landing/event-landing.guard';
import { RedirectPageComponent } from './okta-integration/components/redirect-page/redirect-page.component';
import { SsoPageComponent } from './okta-integration/components/sso-page/sso-page.component';
import { PaymentComponent } from './payment/payment.component';
import { SeatSelectionComponent } from './seat-selection/seat-selection.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { EventDataResolver } from './shared/resolvers/event-data.resolver';
import { eventLandingResolver } from './shared/resolvers/event-landing.resolver';
import { opponentsResolver } from './shared/resolvers/opponents.resolver';
import { SummaryComponent } from './summary/summary.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'seat-selection',
  },
  {
    path: 'okta/redirect',
    component: RedirectPageComponent,
  },
  {
    path: 'login',
    component: SsoPageComponent,
  },
  {
    path: '',
    children: [
      {
        path: 'events',
        component: EventLandingComponent,
        canActivate: [eventLandingGuard],
        resolve: {
          eventList: eventLandingResolver,
          opponentList: opponentsResolver,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'seat-selection',
        component: SeatSelectionComponent,
        resolve: {
          event: EventDataResolver,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'login',
        component: OktaCallbackComponent,
      },
      {
        path: 'checkout/:id', // <---- Transaction id
        component: CheckoutComponent,
      },
      {
        path: 'payment/:id', // <---- Transaction id
        component: PaymentComponent,
      },
      {
        path: 'summary/:id', // <---- Transaction id
        component: SummaryComponent,
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
    ],
  },

  {
    path: '**', // Unknown route protection
    redirectTo: 'seat-selection',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
