import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  setDefaultEnvironment,
  setDefaultModuleVersion,
} from '@3ddv/dvm-internal';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSliderModule } from 'ngx-slider-v2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SeatPopoverComponent } from './digital-venue/popovers/seat-popover/seat-popover.component';
import { SectionPopoverComponent } from './digital-venue/popovers/section-popover/section-popover.component';
import { EventLandingComponent } from './event-landing/event-landing.component';
import { SaleEventComponent } from './event-landing/sale-event/sale-event.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { PaginationModule } from './layout/pagination/pagination.module';
import { LoginComponent } from './login/login.component';
import { OktaIntegrationModule } from './okta-integration/okta-integration.module';
import { LegalTermsComponent } from './payment/legal-terms/legal-terms.component';
import { PaymentComponent } from './payment/payment.component';
import { CartComponent } from './seat-selection/cart/cart.component';
import { DvmComponent } from './seat-selection/dvm/dvm.component';
import { FiltersComponent } from './seat-selection/filters/filters.component';
import { SeatSelectionComponent } from './seat-selection/seat-selection.component';
import { UpsellsComponent } from './seat-selection/upsells/upsells.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { TimerComponent } from './shared/components/timer/timer.component';
import { NumbersInputDirective } from './shared/directives/numbers-input.directive';
import { EnvironmentInterceptor } from './shared/interceptors/environment.interceptor';
import { ForbiddenEventModalComponent } from './shared/modals/forbidden-event-modal/forbidden-event-modal.component';
import { GaModalComponent } from './shared/modals/ga-modal/ga-modal.component';
import { ModalsComponent } from './shared/modals/modals.component';
import { RestrictedSeatsModalComponent } from './shared/modals/restricted-seats-modal/restricted-seats-modal.component';
import { SeatDefinitionsModalComponent } from './shared/modals/seat-definitions-modal/seat-definitions-modal.component';
import { TutorialWelcomeModalComponent } from './shared/modals/tutorial-welcome-modal/tutorial-welcome-modal.component';
import { ConfigurationService } from './shared/services/configuration.service';
import { TemplatePageTitleStrategyService } from './shared/services/template-page-title-strategy.service';
import { SummaryComponent } from './summary/summary.component';

// // Declare DVM versions
setDefaultModuleVersion('map_viewer', 'stable');
setDefaultModuleVersion('3d_viewer', 'stable');
setDefaultEnvironment('core_pro');

/**
 * Initializes application with certain parameters fetched from the URL and backend.
 * It's intended to run before the application fully bootstraps.
 * @param http - The HTTP client used for making the request.
 * @returns A function that returns a promise, signaling the app initialization completion.
 */
function initializeApp(configurationService: ConfigurationService): () => void {
  return () => configurationService.init();
}

const appInitObject = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [ConfigurationService],
  multi: true,
};

const environmentInterceptorObject = {
  provide: HTTP_INTERCEPTORS,
  useClass: EnvironmentInterceptor,
  multi: true,
};

const titleStrategy = {
  provide: TitleStrategy,
  useClass: TemplatePageTitleStrategyService,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FiltersComponent,
    CartComponent,
    SeatSelectionComponent,
    CheckoutComponent,
    PaymentComponent,
    SummaryComponent,
    LayoutComponent,
    DvmComponent,
    LoginComponent,
    ModalsComponent,
    SeatDefinitionsModalComponent,
    GaModalComponent,
    RestrictedSeatsModalComponent,
    SectionPopoverComponent,
    SeatPopoverComponent,
    TimerComponent,
    UpsellsComponent,
    NotFoundComponent,
    NumbersInputDirective,
    EventLandingComponent,
    SaleEventComponent,
    FooterComponent,
    TutorialWelcomeModalComponent,
    ForbiddenEventModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarModule,
    PaginationModule,
    NgxSliderModule,
    OktaIntegrationModule,
    LegalTermsComponent,
  ],
  providers: [titleStrategy, appInitObject, environmentInterceptorObject],
  bootstrap: [AppComponent],
})
export class AppModule {}
