import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Upsell } from 'src/app/shared/models';
import { PrivateSellingService } from '../../shared/services/private-selling.service';

@Injectable({
  providedIn: 'root',
})
export class UpsellsService {
  upsells: Upsell[];
  totalCartAndFiltersSeats$ = new Subject<number>(); // Cada vez que cambia (se hace un ".next") se lanza el this.checkTotalSeatsForUpsells(totalCartSeats) desde upsells.component.ts
  // totalCartSeats: number;
  upsellsCall: ReturnType<typeof setTimeout>;
  // numberSeats = new Subject<number>();
  // numberAccessibleSeats = new Subject<number>();

  reachingUpsell: Upsell | null = null;
  acquiredUpsell: Upsell | null = null;

  availableUpsells: Upsell[];
  acquiredUpsells: Upsell[];
  previousTotalSeats: number;

  private privateSellingService = inject(PrivateSellingService);

  sendLastUpsellsCall(numSeats: number) {
    if (this.upsellsCall) {
      clearTimeout(this.upsellsCall);
    }
    // Updating totalCartAndFiltersSeats number for Upsells
    this.upsellsCall = setTimeout(() => {
      // console.log(numSeats);
      this.totalCartAndFiltersSeats$.next(numSeats);
    }, 1000);
  }

  sortUpsellsByGoal(upsells) {
    if (upsells?.length) {
      upsells.sort((a, b) => a.goal - b.goal);
    }
  }

  checkCartSeatsForUpsellsList(totalCartSeats: number) {
    if (!this.upsells?.length) return;

    this.sortUpsellsByGoal(this.upsells);
    // console.log('Upsells: ', this.upsells);

    // console.log('Prev and total Seats: ', this.previousTotalSeats, totalCartSeats);
    // Reset Upsells lists, available and acquired
    this.availableUpsells = [];
    this.acquiredUpsells = [];
    // this.reachingUpsell = null;
    // this.acquiredUpsell = null;

    for (const upsell of this.upsells) {
      // Set Upsells lists, available and acquired
      if (upsell.goal <= totalCartSeats) {
        this.acquiredUpsells.push(upsell);
        // console.log('ACQUIRED UPSELLS: ', this.acquiredUpsells);
      } else {
        this.availableUpsells.push(upsell);
        // console.log('AVAILABLE UPSELLS: ', this.availableUpsells);
      }

      // // Si el num seats coincide con un Goal
      // if (upsell.goal === totalCartSeats) {
      //   this.acquiredUpsell = upsell;
      //   setTimeout(() => this.acquiredUpsell = null, 3000);
      //   // console.log('Acquiring Upsell: ', this.acquiredUpsell);
      // // Si el num seats coincide con un minSeatsToShow
      // } else if (upsell.minSeatsToShow === totalCartSeats) {
      //   this.reachingUpsell = upsell;
      //   setTimeout(() => this.reachingUpsell = null, 3000);
      //   // console.log('Reaching Upsell: ', this.reachingUpsell);
      // }
    }

    // Si el num seats tiene una diferencia con el num previo de más de 1 (se ha introducido por el input manualmente)
    // if (this.previousTotalSeats - totalCartSeats < -1 || this.previousTotalSeats - totalCartSeats > 1) {
    //   if (this.availableUpsells[0] && this.availableUpsells[0].minSeatsToShow < totalCartSeats) {
    //     this.reachingUpsell = this.availableUpsells[0];
    //     setTimeout(() => this.reachingUpsell = null, 3000);
    //     // console.log('Reaching Upsell: ', this.reachingUpsell);
    //   }
    // }

    // // Store previous seats count
    // this.previousTotalSeats = totalCartSeats;
  }

  checkTotalSeatsForUpsellsMessages(totalSeats: number) {
    if (!this.upsells?.length) return;
    if (
      this.privateSellingService.isEnabled() &&
      !this.privateSellingService.isUserInWhitelist()
    ) {
      return;
    }

    this.sortUpsellsByGoal(this.upsells);

    let availableUps = [];
    let acquiredUps = [];
    for (const upsell of this.upsells) {
      // Set Upsells lists, available and acquired
      if (upsell.goal <= totalSeats) {
        acquiredUps.push(upsell);
        // console.log('ACQUIRED UPSELLS: ', acquiredUps);
      } else {
        availableUps.push(upsell);
        // console.log('AVAILABLE UPSELLS: ', availableUps);
      }
    }

    for (const upsell of this.upsells) {
      // Si el num seats coincide con un Goal
      if (upsell.goal === totalSeats) {
        this.acquiredUpsell = upsell;
        setTimeout(() => (this.acquiredUpsell = null), 3000);
        // console.log('Acquiring Upsell: ', this.acquiredUpsell);
        // Si el num seats coincide con un minSeatsToShow
      } else if (upsell.minSeatsToShow === totalSeats) {
        this.reachingUpsell = upsell;
        setTimeout(() => (this.reachingUpsell = null), 3000);
        // console.log('Reaching Upsell: ', this.reachingUpsell);
      }
    }

    // Si el num seats tiene una diferencia con el num previo de más de 1 (se ha introducido por el input manualmente)
    if (
      this.previousTotalSeats - totalSeats < -1 ||
      this.previousTotalSeats - totalSeats > 1
    ) {
      if (availableUps[0] && availableUps[0].minSeatsToShow < totalSeats) {
        this.reachingUpsell = availableUps[0];
        setTimeout(() => (this.reachingUpsell = null), 3000);
        // console.log('Reaching Upsell: ', this.reachingUpsell);
      }
    }

    // Store previous seats count
    this.previousTotalSeats = totalSeats;
  }
}
