<div *ngIf="(isAuthenticated$ | async) === false; else logout">
  <span>Have account credit or digital wallet?</span
  ><a (click)="signInRedirect()" class="ml-5"
    >Login to your {{ configurationService.configuration.clientName }} account
    to use it.</a
  >
</div>

<ng-template #logout>
  <span>Welcome {{ this.patron?.name }}</span>
</ng-template>
