import { Component, OnInit } from '@angular/core';
import { ChangeContext, Options } from 'ngx-slider-v2';
import { BestAvailableService } from 'src/app/digital-venue/services/best-available.service';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
import { SeatManagementService } from '../../digital-venue/services/seat-management.service';
import { GeneralAvailability } from '../../shared/models';
import { Configuration } from '../../shared/models/configuration.model';
import {
  AvailabilityService,
  CartService,
  UtilitiesService,
} from '../../shared/services';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { UpsellsService } from '../upsells/upsells.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  isMobile: boolean;
  tutorialOn: boolean = false;

  get minSeatsLimit() {
    return this.availabilityService.minSeatsLimit;
  }
  get maxSeatsLimit() {
    return this.availabilityService.maxSeatsLimit;
  }

  minValue: number;
  maxValue: number;
  sliderOptions: Options;
  loadSlider: boolean;

  get numberSeats() {
    return this._numberSeats;
  }
  set numberSeats(value) {
    this._numberSeats = value;
  }

  get clubConfiguration(): Configuration {
    return this.configurationService.configuration;
  }

  _numberSeats: number;
  numberAccessibleSeats: number;

  actualSectionFilteredByPrice: string[] = [];

  // Cart seats
  cartSeats: number = 0;

  constructor(
    private dvmService: DvmService,
    private cartService: CartService,
    private availabilityService: AvailabilityService,
    private upsellsService: UpsellsService,
    private bestAvailableService: BestAvailableService,
    private utilitiesService: UtilitiesService,
    private configurationService: ConfigurationService,
    public seatManagementService: SeatManagementService,
    private tutorialService: TutorialService
  ) {}

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.sliderOptions = {
      floor: 0,
      ceil: 1000,
    };
    this.minValue = 0;
    this.maxValue = 1000;

    this.availabilityService.priceRange$.subscribe({
      next: response => {
        this.sliderOptions = {
          floor: response.min,
          ceil: response.max,
        };
        this.minValue = response.min;
        this.maxValue = response.max;
        // console.log('min-MAX Value', this.minValue, this.maxValue);
        // console.log('Options', this.sliderOptions);
      },
    });

    this.numberSeats = this.minSeatsLimit;
    this.numberAccessibleSeats = 0;

    this.seatManagementService.needADA = false;

    //* UPSELLS
    // Subcribe to cart total seats changes to update Upsells benefits list
    this.cartService.selectedSeats$.subscribe({
      next: response => {
        this.cartSeats = Object.keys(response).length;
      },
      error: error => console.error(error),
    });
  }

  /**
   *
   * @version 1.3.0
   */
  onUserChangeEnd(changeContext: ChangeContext): void {
    if (this.tutorialService.isOpen()) {
      return;
    }

    // New Availability
    // map mmc ga separated sectors to bleacher
    // Quitadas hasta nuevo aviso
    // if (newAvailability.includes(this.dvmService.bleacherSection)) {
    //   if (
    //     this.availabilityService.sectionsAvailability[
    //       this.dvmService.bleacherSection
    //     ].availableQuantityAda > 0
    //   ) {
    //     newAvailability = newAvailability.concat(
    //       this.configurationService.configuration.dvmData.gaAdaIdArray
    //     );
    //   }
    //   if (
    //     this.availabilityService.sectionsAvailability[
    //       this.dvmService.bleacherSection
    //     ].availableQuantity -
    //       this.availabilityService.sectionsAvailability[
    //         this.dvmService.bleacherSection
    //       ].availableQuantityAda >
    //     0
    //   ) {
    //     newAvailability = newAvailability.concat(
    //       this.configurationService.configuration.dvmData.gaSectorsIdArray
    //     );
    //   }
    // }
    this.applyFilters();
    // restart nodes selected for best available
    this.bestAvailableService.selectedSectionsForBA = [];
  }

  addBestSeats() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.stringTypeSavior();
    this.stringTypeSaviorADA();
    // https://mmcbcn.atlassian.net/browse/IT-2748 ada included in the best available number of seats
    const totalNumberOfSeats = this.numberSeats - this.numberAccessibleSeats;

    this.bestAvailableService.getBestXSeats(
      totalNumberOfSeats,
      this.numberAccessibleSeats
    );
  }

  /**
   * BA algorithm can only be executed in the top view.
   * When user enter on section or 3d, disable button
   * @returns
   */
  bestAvailableIsAvailable(): boolean {
    return this.dvmService.isSectionMap || this.dvmService.is3dOpen;
  }

  /**
   * Substract one seat
   * @version 1.3.0
   */
  oneSeatLess() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.stringTypeSavior();
    if (
      this.numberSeats > this.minSeatsLimit &&
      this.numberSeats <= this.maxSeatsLimit
    ) {
      this.numberSeats -= 1;
    } else {
      this.numberSeats = this.minSeatsLimit;
    }
    this.checkRegularSeatsHigherThanAda();
    this.upsellsService.sendLastUpsellsCall(this.numberSeats + this.cartSeats);
    this.applyFilters();
  }

  /**
   * On focut out from input validate some values
   * @version 1.3.0
   */
  onFocusOut(type: 'regular' | 'ada' = 'regular') {
    if (type === 'regular') {
      this.stringTypeSavior();
      this.checkRegularSeatsHigherThanAda();
      this.upsellsService.sendLastUpsellsCall(
        this.numberSeats + this.cartSeats
      );
    } else if (type === 'ada') {
      this.stringTypeSaviorADA();
    }
    this.applyFilters();
  }

  /**
   * Add one more seat
   * @version 1.3.0
   */
  oneSeatMore() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.stringTypeSavior();
    if (
      this.numberSeats >= this.minSeatsLimit &&
      this.numberSeats < this.maxSeatsLimit
    ) {
      this.numberSeats += 1;
    } else {
      this.numberSeats = this.maxSeatsLimit;
    }
    this.upsellsService.sendLastUpsellsCall(this.numberSeats + this.cartSeats);
    this.applyFilters();
  }

  // Add accessible seat to user selection
  oneAccSeatLess() {
    this.stringTypeSaviorADA(); //* prevent string values on input
    if (
      this.numberAccessibleSeats > 0 &&
      this.numberAccessibleSeats <= this.numberSeats
    ) {
      this.numberAccessibleSeats -= 1;
    }
  }

  // Remove accessible seat to user selection
  oneAccSeatMore() {
    this.stringTypeSaviorADA(); //* prevent string values on input
    if (
      this.numberAccessibleSeats >= 0 &&
      this.numberAccessibleSeats < this.numberSeats
    ) {
      this.numberAccessibleSeats += 1;
    }
  }

  closeAdaSelector(): void {
    this.seatManagementService.needADA = false;
    this.numberAccessibleSeats = 0;
    this.availabilityService.deactivateOnlyAdaSections();
  }

  activeAda(): void {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.seatManagementService.needADA = true;
    this.availabilityService.activeAdaSections();
  }

  private stringTypeSavior() {
    if (typeof this.numberSeats === 'string') {
      if (
        this.numberSeats === '' ||
        parseInt(this.numberSeats) < this.minSeatsLimit
      ) {
        this.numberSeats = this.minSeatsLimit;
      } else if (parseInt(this.numberSeats) > this.maxSeatsLimit) {
        this.numberSeats = this.maxSeatsLimit;
      } else {
        this.numberSeats = parseInt(this.numberSeats);
      }
    }
  }

  private stringTypeSaviorADA() {
    if (typeof this.numberAccessibleSeats === 'string') {
      if (
        this.numberAccessibleSeats === '' ||
        parseInt(this.numberAccessibleSeats) < 0
      ) {
        this.numberAccessibleSeats = this.minSeatsLimit;
      } else if (parseInt(this.numberAccessibleSeats) > this.numberSeats) {
        this.numberAccessibleSeats = this.numberSeats;
      } else {
        this.numberAccessibleSeats = parseInt(this.numberAccessibleSeats, 10);
      }
    }
  }

  // If the number of regular seats is lower than the number of ada seats,
  // the ada seats cannot be higher than the number of seats, we must equal both values.
  private checkRegularSeatsHigherThanAda(): void {
    if (this.numberSeats < this.numberAccessibleSeats) {
      this.numberAccessibleSeats = this.numberSeats;
    }
  }

  /**
   * Filters the sections that meet the selected chairs
   * @since 1.3.0
   * @version 1.3.0
   */
  private filterSectionsBySeats(
    sectionsAvailability: GeneralAvailability[]
  ): GeneralAvailability[] {
    return sectionsAvailability.filter(
      item => item.availableQuantity > this.numberSeats
    );
  }

  private filterSectionsByPrice(
    sectionsAvailability: GeneralAvailability[]
  ): GeneralAvailability[] {
    let newAvailability: GeneralAvailability[] = [];
    sectionsAvailability.forEach(data => {
      Object.values(data.priceScales).forEach(priceScale => {
        // if section has price between this values
        if (
          priceScale.price.value <= this.maxValue &&
          priceScale.price.value >= this.minValue
        ) {
          newAvailability.push(data);
        }
      });
    });

    return newAvailability;
  }

  private applyFilters() {
    const availability = this.availabilityService.sectionsAvailability;
    const availabilityList = Object.values(availability);
    let sections = this.filterSectionsBySeats(availabilityList);
    sections = this.filterSectionsByPrice(sections);
    let finalSection = sections.map(item => item.sectionID);
    this.dvmService.viewer.setAvailability('section', finalSection);
  }

  // For input numbers fixing. Actually using the Numbers Input directive
  // seatsDataChanged(value) {
  //   // console.log('Input value: ', value);
  //   // console.log('numberSeats: ', this.numberSeats);
  //   let inputValue = value;
  //   // let inputValue = this.numberSeats.toString();
  //   let goodResult = '';

  //   if (this.onlyHasNumbers(inputValue) === false) {
  //     for (let i = 0; i < inputValue.length; i++) {
  //       // console.log('condition', this.onlyHasNumbers(inputValue[i]) === false);
  //       if (this.onlyHasNumbers(inputValue[i]) === true) {
  //         // console.log('is Number', inputValue[i]);
  //         goodResult = goodResult.concat(inputValue[i]);
  //       }
  //     }
  //     console.log('goodResult: ', goodResult);
  //     if (goodResult) {
  //       this.numberSeats = parseInt(goodResult);
  //     } else {
  //       this.numberSeats = this.minSeatsLimit;
  //     }
  //     document.getElementById('input-seats')['value'] = this.numberSeats;
  //   }
  //   // this.stringTypeSavior();
  //   console.log('Number Seats: ', this.numberSeats);
  // }

  // /**
  //  * Check using RegExp if string has only numbers
  //  * @param {string} value - item to test
  //  * @returns {boolean} - true if only has numbers, otherwise false
  // */
  // onlyHasNumbers(value): boolean {
  //   return this.onlyNumbers.test(value);
  // }
}
