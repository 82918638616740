import { Component, inject } from '@angular/core';
import { UtilitiesService } from '../../services';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  public isMobile = inject(UtilitiesService).isMobile;
  public configurationService = inject(ConfigurationService);
}
