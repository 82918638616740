<div
  (mouseenter)="onEnter($event)"
  (mouseleave)="onLeave($event)"
  class="w-80 h-auto text-white bg-black rounded-xl overflow-hidden">
  <div class="w-full h-48">
    <!-- <div *ngIf="tooltipData.img" class='h-full bg-center bg-cover bg-no-repeat'[ngStyle]="{'background-image': 'url('+tooltipData.img+')'}"> -->
    <div
      *ngIf="tooltipData.img"
      (click)="load3dView()"
      class="viewer3d-image h-full bg-center bg-cover bg-no-repeat"
      [style.background-image]="'url(' + tooltipData.img + ')'">
      <span>Click to open view</span>
    </div>
    <div
      *ngIf="!tooltipData.img"
      class="w-full h-full flex justify-center items-center">
      <div class="lds-dual-ring"></div>
    </div>
  </div>

  <div class="px-3 py-2">
    <div class="flex">
      <div class="w-1/2 mr-5">
        <h5>
          <span class="font-normal">Section:</span>
          {{ sectionData.mmcID.split('_')[1] }}
        </h5>
        <h5><span class="font-normal">Price:</span> $ {{ priceRange }}</h5>
      </div>
      <div class="w-1/2 min-w-max text-right">
        <h5>
          <span class="font-normal">Available Seats:</span>
          {{ sectionData.availableQuantity }}
        </h5>
        <h5>
          <span class="font-normal">Available ADA Seats:</span>
          {{ adaQuantity }}
        </h5>
      </div>
    </div>
    <div class="py-2 flex justify-center">
      <button
        *ngIf="isMobile"
        class="btn-main mr-2 h-9 text-xs"
        (click)="selectSectionForBA()">
        <span *ngIf="!includedSectionForBA">Select</span>
        <span *ngIf="includedSectionForBA">Deselect</span>
      </button>
      <button
        *ngIf="
          this.tooltipData.section !==
            'S_BLCHADA==============================' &&
          this.tooltipData.section !==
            'S_Bleache==============================r' &&
          this.tooltipData.type !== 'general_admission'
        "
        class="btn-white h-9 text-xs text-main"
        (click)="openSeatMap()">
        Open Seat Map
      </button>
    </div>
  </div>
</div>

<!-- <div class="popover-section-content text-center">
  <div class="section">
    Section: {{sectionData.mmcID.split('S_')[1]}}
  </div>
  <div class="available-quantity">
    Seats available: {{sectionData.availableQuantity}}
  </div>
  <div class="price-range">
    Price: {{priceRange}}
  </div>
</div> -->
