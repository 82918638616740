import { MapViewerNode } from '@3ddv/dvm-internal';
import {
  GeneralAvailabilityDict,
  SeatAvailabilitySectionDict,
} from '../models';

export class TutorialMocks {
  public static getSectionAvailability(
    sectionAvailabilityIds: Array<string>,
    availableSeatsQuantity: number
  ): GeneralAvailabilityDict {
    return sectionAvailabilityIds.reduce(
      (result, sectionId) => ({
        ...result,
        [sectionId]: TutorialMocks.getSectionAvailabilityItem(
          sectionId,
          availableSeatsQuantity
        ),
      }),
      {}
    );
  }

  private static getSectionAvailabilityItem(
    sectionID: string,
    availableSeatsQuantity: number
  ) {
    return {
      sectionID,
      priceScales: [
        {
          price: {
            currencyCode: 'USD',
            value: 469,
          },
          priceScaleID: '2295',
          availableQuantity: availableSeatsQuantity,
          derivedDescription: 'Dugout Club Dugout Club 113',
          buyerTypes: [
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3161,
                buyerType: 'Adult',
                price: {
                  currencyCode: 'USD',
                  value: 469,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3162,
                buyerType: 'Child',
                price: {
                  currencyCode: 'USD',
                  value: 293.45,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3163,
                buyerType: 'Senior',
                price: {
                  currencyCode: 'USD',
                  value: 410.7,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3164,
                buyerType: 'Military',
                price: {
                  currencyCode: 'USD',
                  value: 410.7,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3167,
                buyerType: 'Upgrade',
                price: {
                  currencyCode: 'USD',
                  value: 500,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3170,
                buyerType: 'Full Season',
                price: {
                  currencyCode: 'USD',
                  value: 449,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3171,
                buyerType: 'Half Season',
                price: {
                  currencyCode: 'USD',
                  value: 224.5,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3181,
                buyerType: 'Quarter Season',
                price: {
                  currencyCode: 'USD',
                  value: 112.25,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3201,
                buyerType: 'Bundle Buyer',
                price: {
                  currencyCode: 'USD',
                  value: 503.9,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3441,
                buyerType: 'Coupon Discount',
                price: {
                  currencyCode: 'USD',
                  value: 426.55,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3461,
                buyerType: 'MLB Coupon 2',
                price: {
                  currencyCode: 'USD',
                  value: 404.1,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3621,
                buyerType: 'Targeted Promotion',
                price: {
                  currencyCode: 'USD',
                  value: 449,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 3822,
                buyerType: 'Print Restricted Ticket',
                price: {
                  currencyCode: 'USD',
                  value: 449,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 4063,
                buyerType: 'Sales Group Offer Buyer Type T',
                price: {
                  currencyCode: 'USD',
                  value: 449,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 4222,
                buyerType: 'Mobile Media Content',
                price: {
                  currencyCode: 'USD',
                  value: 449,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 4263,
                buyerType: 'PROMO|Test123',
                price: {
                  currencyCode: 'USD',
                  value: 170,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 4264,
                buyerType: 'PROMO|Test456',
                price: {
                  currencyCode: 'USD',
                  value: 180,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 4262,
                buyerType: 'LV|5.00',
                price: {
                  currencyCode: 'USD',
                  value: 165,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 4302,
                buyerType: 'TicketRev',
                price: {
                  currencyCode: 'USD',
                  value: 426.55,
                },
              },
            },
            {
              availableQuantity: availableSeatsQuantity,
              buyerType: {
                buyerTypeID: 4543,
                buyerType: 'Public Sale Buyer',
                price: {
                  currencyCode: 'USD',
                  value: 100,
                },
              },
            },
          ],
        },
      ],
      availableQuantity: availableSeatsQuantity,
      priceRange: [
        {
          currencyCode: 'USD',
          value: 469,
        },
        {
          currencyCode: 'USD',
          value: 469,
        },
      ],
      availableQuantityAda: 0,
    };
  }

  public static getSeatsAvailability(
    selectedSectionId: string,
    sectionSeatNodes: Array<MapViewerNode>
  ): SeatAvailabilitySectionDict {
    const seatsAvailability = sectionSeatNodes.reduce(
      (result, seat) => ({
        ...result,
        [seat.id]: TutorialMocks.getSeatsAvailabilityItem(seat.id),
      }),
      {}
    );

    return { [selectedSectionId]: seatsAvailability };
  }

  private static getSeatsAvailabilityItem(seatId: string) {
    return {
      id: seatId,
      seatDefinition: [
        {
          id: 1794,
          description: 'DC',
          code: 'DC',
          restriction: false,
        },
        {
          id: 1812,
          description: 'Dugout Club',
          code: 'DUG-D',
          restriction: false,
        },
        {
          id: 1837,
          description: 'Right Aisle',
          code: 'RAISLE',
          restriction: false,
        },
      ],
      price: [
        {
          buyerTypeId: 3161,
          buyerTypeName: 'Adult',
          currencyCode: 'USD',
          value: 469,
        },
        {
          buyerTypeId: 3164,
          buyerTypeName: 'Military',
          currencyCode: 'USD',
          value: 410.7,
        },
        {
          buyerTypeId: 3167,
          buyerTypeName: 'Upgrade',
          currencyCode: 'USD',
          value: 500,
        },
        {
          buyerTypeId: 4222,
          buyerTypeName: 'Mobile Media Content',
          currencyCode: 'USD',
          value: 449,
        },
        {
          buyerTypeId: 3170,
          buyerTypeName: 'Full Season',
          currencyCode: 'USD',
          value: 449,
        },
        {
          buyerTypeId: 3171,
          buyerTypeName: 'Half Season',
          currencyCode: 'USD',
          value: 224.5,
        },
        {
          buyerTypeId: 4262,
          buyerTypeName: '$5 Loaded Value',
          currencyCode: 'USD',
          value: 165,
        },
        {
          buyerTypeId: 3181,
          buyerTypeName: 'Quarter Season',
          currencyCode: 'USD',
          value: 112.25,
        },
        {
          buyerTypeId: 4263,
          buyerTypeName: 'PROMO|Test123',
          currencyCode: 'USD',
          value: 170,
        },
        {
          buyerTypeId: 3201,
          buyerTypeName: 'Bundle Buyer',
          currencyCode: 'USD',
          value: 493.9,
        },
        {
          buyerTypeId: 4264,
          buyerTypeName: 'PROMO|Test456',
          currencyCode: 'USD',
          value: 180,
        },
        {
          buyerTypeId: 4302,
          buyerTypeName: 'TicketRev',
          currencyCode: 'USD',
          value: 426.55,
        },
        {
          buyerTypeId: 3441,
          buyerTypeName: 'Coupon Code Discounted Ticket',
          currencyCode: 'USD',
          value: 426.55,
        },
        {
          buyerTypeId: 3461,
          buyerTypeName: 'MLB Coupon 2',
          currencyCode: 'USD',
          value: 404.1,
        },
        {
          buyerTypeId: 3621,
          buyerTypeName: 'Targeted Promotion',
          currencyCode: 'USD',
          value: 449,
        },
        {
          buyerTypeId: 3161,
          buyerTypeName: 'Adult',
          currencyCode: 'USD',
          value: 469,
        },
        {
          buyerTypeId: 3822,
          buyerTypeName:
            'This ticket can only be printed via specific applications. ',
          currencyCode: 'USD',
          value: 449,
        },
        {
          buyerTypeId: 4063,
          buyerTypeName: 'Sales Group Offer Buyer Type Trait',
          currencyCode: 'USD',
          value: 449,
        },
        {
          buyerTypeId: 4543,
          buyerTypeName: 'Public Sale Buyer',
          currencyCode: 'USD',
          value: 100,
        },
        {
          buyerTypeId: 3162,
          buyerTypeName: 'Child',
          currencyCode: 'USD',
          value: 293.45,
        },
        {
          buyerTypeId: 3163,
          buyerTypeName: 'Senior',
          currencyCode: 'USD',
          value: 410.7,
        },
      ],
      isAda: false,
    };
  }
}
