import { DOCUMENT } from '@angular/common';
import { Injectable, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  configurationData,
  configurationSchema,
} from '../club-configurations/configuration-data';
import { Configuration, EntityKeys } from '../models/configuration.model';

/**
 * Service for managing application configuration. This service initializes the configuration based on the environment
 * extracted from the hostname. It validates the configuration against a predefined schema, fills the configuration
 * with environment-specific values, and declares global CSS variables based on the configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public environment: EntityKeys;
  public defaultConfig = configurationData['default'];
  public configuration: Configuration;
  private _injector = inject(Injector);
  private _document = inject(DOCUMENT);
  private _domDocument = document;

  /**
   * Initializes the service by determining the environment from the hostname,
   * loading the respective configuration, validating it, filling it with environment-specific values,
   * and declaring the global CSS variables.
   *
   * @throws If the configuration does not adhere to the predefined schema.
   */
  public init = () => {
    const client = this.getEnvironmentFromHostname();
    let configuration = configurationData[client];
    const { success } = configurationSchema.safeParse(configuration);
    const isLocalEnvironment = location.href.includes('local');

    // If after parsing, the club was not found in configurationData, redirect to not found
    if (
      !success ||
      client === 'default' ||
      typeof configurationData[client] === 'undefined'
    ) {
      this.configuration = this.fallbackToDefaultConfig();
      if (!isLocalEnvironment) {
        this.navigateToNotFound();
        return;
      }
    }

    configuration = configuration ?? this.configuration;

    this._document
      .getElementById('appFavicon')
      .setAttribute('href', configuration?.favicon);
    this.fillWithEnvConfiguration(configuration);
    this.declareGlobalCssVariables();
  };

  /**
   * Updates the configuration IDs, categorizing them into
   * sectors and ADA-specific arrays based on their identifiers. This method segregates GA IDs
   * into `gaSectorsIdArray` for general sectors and `gaAdaIdArray` for ADA-specific identifiers,
   * assuming these arrays are initially empty. It's designed for a one-time initialization or
   * setup phase, where GA IDs are pre-processed and stored for future use.
   *
   * @param gaIds - An array of General Availability sector identifiers to be processed and categorized.
   *                The function filters these IDs into two categories: those containing
   *                'ADA' are placed into `gaAdaIdArray`, and those without into `gaSectorsIdArray`.
   *
   * @remarks
   * - This method only initializes `gaSectorsIdArray` and `gaAdaIdArray` if they are empty. If these arrays
   *   already contain elements, the method will not modify them.
   * - It is assumed that the `configuration` object and its `dvmData` property follow a specific structure
   *   required by this method. The method will not work as intended if this structure is not adhered to.
   */
  public addGAToConfiguration(gaIds: string[]) {
    if (!this.configuration.dvmData.gaSectorsIdArray.length) {
      const gaSectorIds = gaIds.filter(node => !node.includes('ADA'));
      this.configuration.dvmData.gaSectorsIdArray = gaSectorIds;
    }
    if (!this.configuration.dvmData.gaAdaIdArray.length) {
      const gaAdaIds = gaIds.filter(node => node.includes('ADA'));
      this.configuration.dvmData.gaAdaIdArray = gaAdaIds;
    }
  }

  /**
   * Determines the environment based on the hostname in the URL.
   *
   * @returns The name of the environment.
   */
  private getEnvironmentFromHostname() {
    //https://regex101.com/
    // Capture string prefixing ".groupsales.3ddigitalvenue.com".
    // ?(.*) is the key expression that extracts the client name
    const regex: RegExp =
      /^(?:http|https):\/\/(?:local\.)?(?:private\.)?(?:demo-)?(?:dev-)?(?:pre-)?(?:uat-)?(.*)?(?:\.groupsales)?\.3ddigitalvenue\.com.*/gm;
    const matches = regex.exec(location.href);
    const client: any = matches?.[1]?.split('.')?.[0] as EntityKeys;
    const isLocalEnvironment = false;
    // If after matching URL, the client is 'default', meaning it was not found, redirect to not found page
    if ((!isLocalEnvironment && client === 'default') || client == null) {
      this.configuration = this.fallbackToDefaultConfig();
      this.navigateToNotFound();
    } else {
      this.environment = client;
    }

    return client;
  }

  /**
   * Fills the service's configuration with values from the specified configuration.
   *
   * @param configuration - The configuration object to load into the service.
   */
  private fillWithEnvConfiguration(configuration: Configuration) {
    this.configuration = {} as Configuration;

    for (const [key, value] of Object.entries(configuration)) {
      this.configuration[key] = value;
    }
  }

  /**
   * Declares CSS variables globally based on the 'theme' and 'dvmStyles' property of the configuration.
   */
  private declareGlobalCssVariables() {
    const root: HTMLElement | null = this._domDocument.querySelector(':root');

    if (root) {
      const theme = this.configuration.theme;
      const dvmStyles = this.configuration.dvmStyles;
      const mergedStyles = Object.assign({}, theme, {
        'seat-available-none-fill': dvmStyles['seat-available-none-fill'],
        'client-dvm-map': dvmStyles['map-height'],
      });

      for (const [key, value] of Object.entries(mergedStyles)) {
        if (typeof value === 'string') {
          root.style.setProperty('--' + key, value);
        }
      }
    }
  }

  private fallbackToDefaultConfig() {
    this.environment = 'default';
    return configurationData['default'];
  }

  private navigateToNotFound() {
    const router = this._injector.get(Router);
    router.navigate(['not-found']);
  }
}
