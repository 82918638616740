import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserClaims } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import * as countryJson from '../../assets/data/country-code.json';
import * as subCountryJson from '../../assets/data/sub-country-code.json';
import { OktaIntegrationService } from '../okta-integration/services/okta-integration.service';
import { ModalsService } from '../shared/modals/modals.service';
import type {
  CountryList,
  SubCountryItem,
  SubCountryObject,
  TransactionModel,
  TransactionWrapper,
} from '../shared/models';
import { type Configuration } from '../shared/models/configuration.model';
import type {
  DigitalWalletItem,
  PatronAddressModel,
  PatronModel,
  PatronPhoneModel,
} from '../shared/models/patron.model';
import { BraintreePayload } from '../shared/models/transaction.model';
import { UtilitiesService } from '../shared/services';
import { ConfigurationService } from '../shared/services/configuration.service';
import {
  AccountCredit,
  ConnectionService,
} from '../shared/services/connection.service';

declare var braintree: any;

export type paymentDetailsAddress = PatronAddressModel;

type paymentDetailsPhone = PatronPhoneModel;

export interface PaymentDetails {
  nonce: string;
  paymentMethod: string;
  cardType: string;
  acceptedTerms: boolean;
  extraCheckbox?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  digitalWallet: DigitalWalletItem | null;
  accountCredit: AccountCredit;
  address: paymentDetailsAddress;
  phone: paymentDetailsPhone;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [CurrencyPipe],
})
export class PaymentComponent implements OnInit {
  isMobile: boolean;
  patron: PatronModel;
  @ViewChild('paymentForm') form: NgForm;
  apiUrl = environment.apiUrl;
  transactionId: string;
  transaction: TransactionWrapper;
  data: { transaction: TransactionModel; btToken: string };
  countryList: CountryList = [];
  subCountryObject: SubCountryObject = {};
  subCountryList: SubCountryItem[] = [];

  paymentDetails: PaymentDetails = {
    nonce: null,
    paymentMethod: null,
    cardType: null,
    acceptedTerms: false,
    firstName: null,
    lastName: null,
    email: null,
    digitalWallet: null,
    accountCredit: {
      credits: 0,
      payFull: false,
    },
    address: null,
    phone: null,
  };

  displayCredits: string = '0.00';

  confirmationPage: boolean;

  get configuration() {
    return this.configurationService.configuration;
  }

  constructor(
    private router: Router,
    private _oktaService: OktaIntegrationService,
    private connectionService: ConnectionService,
    private activatedRoute: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    public configurationService: ConfigurationService,
    private modalsService: ModalsService,
    private currencyPipe: CurrencyPipe
  ) {
    this.data = { transaction: null, btToken: '' };
  }

  ngOnInit(): void {
    this.confirmationPage = false;
    this.transactionId = this.activatedRoute.snapshot.paramMap.get('id');
    this.connectionService.token =
      this.activatedRoute.snapshot.queryParams['token'];

    this.isMobile = this.utilitiesService.isMobile;
    this.paymentDetails.paymentMethod =
      this.configurationService.configuration.paymentMethod;
    const isBraintrePayment = this.paymentDetails.paymentMethod === 'braintree';

    this.connectionService
      .getTransactionById(this.transactionId, isBraintrePayment)
      .subscribe({
        next: async (response: {
          transaction: TransactionModel;
          btToken: string;
        }) => {
          this.data = response;
          this.transaction = response;

          setTimeout(() => {
            const button = document.querySelector('#braintree-button');
            braintree.dropin.create(
              {
                authorization: this.data?.btToken,
                selector: '#dropin-container',
              },
              (err, instance) => {
                button.addEventListener('click', () => {
                  instance.requestPaymentMethod(
                    (_err, payload: BraintreePayload) => {
                      this.paymentDetails.nonce = payload.nonce;
                      this.paymentDetails.cardType =
                        payload.details.cardType.toLowerCase();
                    }
                  );
                });
              }
            );
          }, 300);
        },
        error: error => {
          console.error(error);
          const modalData = {
            title: 'ERROR',
            content: 'An Error occurred while trying to get Transaction data.',
            // closeBtnName: 'CLOSE',
            acceptBtnName: 'CLOSE',
            acceptFunction: this.onTransactionExpiredAction.bind(this),
          };
          // If there's a custom api error.
          if (error.error.hasOwnProperty('code')) {
            modalData.content = error.error.message;
          }
          this.modalsService.openModal(modalData);
        },
      });
    this.countryList = countryJson['default'];
    this.subCountryObject = subCountryJson['default'];
    if (this.configurationService.configuration.extraCheckbox)
      this.paymentDetails['extraCheckbox'] = false;
    if (this.configuration.custom.paymentPage.captureAddress) {
      this.paymentDetails.address = {
        subCountryCode: '',
        city: null,
        countryCode: 'USA',
        postalCode: null,
        address1: null,
      };
      this.subCountryList =
        this.subCountryObject[this.paymentDetails.address.countryCode];
    }
    if (this.configuration.custom.paymentPage.capturePhone) {
      this.paymentDetails.phone = {
        phone: '',
        countryCode: 'USA',
      };
    }
  }

  async checkout() {
    const configuration = this.configurationService.configuration;
    const balance = this.transaction.transaction.tdc_transaction.balance;
    this.paymentDetails.paymentMethod =
      this.configurationService.configuration.paymentMethod;
    const { credits, payFull } = this.paymentDetails.accountCredit;
    if (
      !this.paymentDetails.nonce &&
      !this.paymentDetails.digitalWallet &&
      !payFull
    ) {
      // change message based off logged in patron
      const content = this.patron
        ? 'Please enter valid credit cart, select a digital wallet or pay all with credit. '
        : 'Please enter valid credit card.';
      const modalData = {
        title: 'Payment information',
        content: content,
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }

    if (credits > balance) {
      const modalData = {
        title: 'Account Credit',
        content: 'The credit must be less than or equal to the balance.',
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    if (!this.paymentDetails.acceptedTerms) {
      const modalData = {
        title: 'Accept terms',
        content: 'Please accept the terms and conditions.',
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    const extraCheckboxIsRequiredAndActive =
      configuration.extraCheckbox &&
      configuration.extraCheckbox.active &&
      configuration.extraCheckbox.required;
    if (
      !this.paymentDetails.extraCheckbox &&
      extraCheckboxIsRequiredAndActive
    ) {
      const modalData = {
        title: 'Accept Broker Polcy',
        content: 'Please accept the Brokers Policy.',
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }

    if (
      !this.paymentDetails.firstName ||
      !this.paymentDetails.lastName ||
      !this.paymentDetails.email
    ) {
      const modalData = {
        title: 'Required fields',
        content: 'You have to enter first name, last name and email.',
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    if (this.form.form.controls['email'].invalid) {
      const modalData = {
        title: 'Valid email',
        content: 'Please enter a valid email.',
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }

    //Check address inputs?
    if (this.checkAddressInputs(configuration)) {
      const modalData = {
        title: 'Required fields',
        content: 'You must enter your address data',
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }

    // Check phone inputs if is needed
    if (this.checkPhoneInputs(configuration.custom.paymentPage.capturePhone)) {
      const modalData = {
        title: 'Required fields',
        content: 'You must enter your phone data',
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }

    this.connectionService
      .checkoutTransaction(this.transactionId, this.paymentDetails)
      .subscribe({
        next: () => {
          this.confirmationPage = true;
          setTimeout(() => {
            this.confirmationPage = false;
            this.router.navigate(['', 'summary', this.transactionId], {
              queryParams: { token: this.connectionService.token },
            });
          }, 4000);
        },

        error: error => {
          console.error(error);
          const modalData = {
            title: 'ERROR',
            content:
              'An Error occurred while trying to checkout the Transaction.',
            acceptBtnName: 'CLOSE',
          };

          if (error.error.hasOwnProperty('code') || error.error.message) {
            modalData.content = error.error.message;
          }
          this.modalsService.openModal(modalData);
        },
      });
  }

  /**
   * Should check phone inputs if is needed
   * @since 1.4.0
   * @version 1.4.0
   * @param shouldCapturePhone should i capture phone
   * @returns all is good
   */
  private checkPhoneInputs(shouldCapturePhone: boolean): boolean {
    const checkPhoneForm = shouldCapturePhone && !this.patron;
    const phoneAreValid =
      !this.paymentDetails.phone?.phone ||
      !this.paymentDetails.phone?.countryCode;
    return checkPhoneForm && phoneAreValid;
  }

  /**
   * Only check address inputs if capture address are activate and patron dont exist
   * @param configuration Club Configuration
   * @returns {boolean}
   * @version 1.3.1
   * @since 1.3.1
   */
  private checkAddressInputs(configuration: Configuration): boolean {
    const checkAddressForm =
      configuration.custom.paymentPage.captureAddress && !this.patron;
    const addressInputsAreValid =
      !this.paymentDetails.address?.address1 ||
      !this.paymentDetails.address?.city ||
      !this.paymentDetails.address?.countryCode ||
      !this.paymentDetails.address?.postalCode ||
      !this.paymentDetails.address?.subCountryCode;

    return checkAddressForm && addressInputsAreValid;
  }

  showErrorModal(message: string) {
    const modalData = {
      title: 'Payment information',
      content: message,
      acceptBtnName: 'CLOSE',
    };
    this.modalsService.openModal(modalData);
    return;
  }

  goCheckout() {
    this.router.navigate([`checkout/${this.transactionId}`], {
      queryParams: { token: this.connectionService.token },
    });
  }

  onTransactionExpired() {
    const modalData = {
      title: 'Transaction expired',
      content: 'The transaction has expired, please try again.',
      acceptBtnName: 'CLOSE',
      acceptFunction: this.onTransactionExpiredAction.bind(this),
    };
    this.modalsService.openModal(modalData);
  }

  goSeatSelection() {
    let eventId = 10170; //! todo hardcoded
    // let eventId: number;
    console.log(this.transaction);

    if (this.transaction) {
      eventId = this.transaction.transaction.event.pvEventId;
      // } else {
      //   eventId = this.connectionService.eventId;
    }
    this.router.navigate(['seat-selection'], {
      queryParams: { event: eventId },
    });
  }

  onTransactionExpiredAction() {
    this.connectionService.deleteTransaction(this.transactionId).subscribe({
      next: response => {
        this.goSeatSelection();
      },
      error: error => {
        // no need for a modal here
        this.goSeatSelection();
      },
    });
  }

  infoEmailModal() {
    const clientName = this.configurationService.configuration.clientName;
    const modalData = {
      title: 'MLB Ballpark App',
      content: `${clientName} tickets are available exclusively as mobile tickets via the free MLB Ballpark app. To view and use tickets, log in to your existing MLB.com account or create an account in the MLB Ballpark app with the email used to purchase your ticket(s). Detailed instructions on using the MLB Ballpark app, including email verification, viewing tickets, and forwarding tickets, are available at <a href="${this.configurationService.configuration.clientBallParkUrl}" target="_blank">${this.configurationService.configuration.clientBallParkText}</a>.`,
      acceptBtnName: 'CLOSE',
    };
    this.modalsService.openModal(modalData);
  }

  oktaEventEmitter(instance: UserClaims): void {
    let patron = instance;
    this.connectionService
      .postAssociatePatronToCart(
        patron.sub,
        patron.email,
        parseInt(this.transactionId, 10),
        this.connectionService.token
      )
      .subscribe({
        next: (response: PatronModel) => {
          this.patron = response;
          this.paymentDetails.firstName = response.firstName;
          this.paymentDetails.lastName = response.lastName;
          this.paymentDetails.email = response.email;
          this.paymentDetails.address = response.address;
        },
        error: error => {
          console.log(error);
          const modalData = {
            title: 'ERROR',
            content: 'An Error occurred while trying to change upgrades',
            acceptBtnName: 'CLOSE',
            // acceptFunction: ,
          };
          // If there's a custom api error.
          if (error.error.hasOwnProperty('code')) {
            modalData.content = error.error.message;
          }
          if (
            error.error.hasOwnProperty('code') &&
            error.error.code === 'E_TRANSACTION_RESTART'
          ) {
            modalData['acceptFunction'] = () => {
              this.restartTransaction();
            };
          }
          this.modalsService.openModal(modalData);
        },
      });
  }

  onChangeDigitalWalletSelector(instance): void {
    this.paymentDetails.cardType = this.paymentDetails.digitalWallet
      ? this.paymentDetails.digitalWallet.cardType.description
      : null;
  }

  checkboxPayInFullChange(instance: Event): void {
    const isChecked = <HTMLInputElement>instance.currentTarget;
    // if the account credit covers the balance of the transaction
    if (
      isChecked &&
      this.patron.accountCredit >=
        this.transaction.transaction.tdc_transaction.balance
    ) {
      // give the full amount of the balance in credits for the transaction
      this.paymentDetails.accountCredit.credits =
        this.transaction.transaction.tdc_transaction.balance;
    } else if (
      isChecked &&
      this.patron.accountCredit <=
        this.transaction.transaction.tdc_transaction.balance
    ) {
      // else, just give all the credits the patron has in its account
      this.paymentDetails.accountCredit.credits = this.patron.accountCredit;
    } else if (!isChecked) {
      this.paymentDetails.accountCredit.credits = 0;
    }

    this.formatForDisplay();
  }

  private restartTransaction() {
    this.connectionService.deleteTransaction(this.transactionId).subscribe({
      next: response => {
        this.goSeatSelection();
      },
      error: error => {
        // no need for a modal here
        this.goSeatSelection();
      },
    });
  }

  onBlurInputCredits() {
    const formattedValue = this.currencyPipe.transform(
      this.paymentDetails.accountCredit.credits,
      'USD',
      'symbol',
      '1.2-2'
    );

    this.paymentDetails.accountCredit.credits = +formattedValue;

    if (
      +this.paymentDetails.accountCredit.credits > this.patron.accountCredit
    ) {
      this.paymentDetails.accountCredit.credits = +this.patron.accountCredit;
    }
  }

  onCreditsChange(value: string) {
    const numericValue = parseFloat(value.replace(/,/g, ''));

    this.paymentDetails.accountCredit.credits = isNaN(numericValue)
      ? 0
      : numericValue;

    this.displayCredits = value;
  }

  formatForDisplay() {
    // Format the number with comma separators and two decimal places for display
    this.displayCredits =
      this.paymentDetails.accountCredit.credits.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  }

  onChangeCountry(event: string) {
    this.subCountryList = this.subCountryObject[event];
  }

  get substractCreditsToBalance(): number {
    const balance = this.transaction?.transaction.tdc_transaction.balance;
    const result = balance - this.paymentDetails.accountCredit.credits;
    return result > 0 ? result : 0;
  }

  get showCreditsApplies(): number {
    const credits = this.paymentDetails.accountCredit.credits;
    return credits && credits > 0 ? credits : 0;
  }
}
