<div
  id="header"
  class="w-full fixed flex items-center justify-between text-white"
  [ngClass]="[
    isMobile ? 'h-12 px-2 py-1' : 'h-16 px-3 py-2',
    showLegend ? 'bg-black' : 'bg-main-dark'
  ]">
  <!-- LOGO & CLUB NAME -->
  <div *ngIf="!isMobile" class="mr-6 flex items-center">
    <ng-container *ngIf="!isNotFoundRoute()">
      <img
        [src]="configurationService.configuration.logo"
        alt="Club logo"
        class="h-12" />
      <div class="h-10 flex items-center">
        <h3 class="min-w-max ml-3">
          {{ configurationService.configuration.clientName }}
        </h3>
        <ng-container *ngIf="!landing">
          <div class="h-full mx-5 border-l border-current"></div>
          <h5>
            <span class="mr-1 font-normal">Event:</span>
            {{ event?.description }}
          </h5>
          <div class="h-full mx-5 border-l border-current"></div>
          <h5>
            <span class="mr-1 font-normal"></span>
            {{ event?.date | date: 'MMM d, y, h:mm a' }}
            <span class="text-xs"
              >(All Times {{ this.timeZone }}. Subject to change.)</span
            >
          </h5>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <!-- Mobile -->
  <div *ngIf="isMobile" class="mr-6 flex items-center">
    <div *ngIf="backBtn === 'payment' || backBtn === 'summary'">
      <button class="btn-icon bg-transparent" (click)="goBack(backBtn)">
        <img
          src="/assets/img/arrow-left-white-mobile-icon.svg"
          alt="Arrow left white icon" />
      </button>
    </div>
    <div *ngIf="isSectionMap || is3dOpen">
      <button class="btn-icon bg-transparent" (click)="goBackToTopview()">
        <img
          src="/assets/img/arrow-left-white-mobile-icon.svg"
          alt="Arrow left white icon" />
      </button>
    </div>
    <ng-container *ngIf="!isNotFoundRoute()">
      <img
        [src]="configurationService.configuration.logo"
        alt="Club logo"
        class="h-10" />
      <h5 class="min-w-max ml-2">
        {{ configurationService.configuration.clientName }}
      </h5>
    </ng-container>
  </div>

  <!-- SEAT-SELECTION -->
  <div *ngIf="!isMobile && !is3dOpen" class="ml-6 flex items-center">
    <div id="legend" *ngIf="showLegend" class="flex">
      <P class="mr-3">Legend:</P>
      <div class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-dvm-available rounded-full"></div>
        <p>Available</p>
      </div>
      <div class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-{{ mySeatsColor }} rounded-full"></div>
        <p>My seats</p>
      </div>
      <div *ngIf="isTopview" class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-{{ selectedColor }} rounded-full"></div>
        <p>Selected</p>
      </div>
      <div
        *ngIf="!isTopview && isIsolatedActive"
        class="mr-3 flex items-center">
        <div class="w-2 h-2 mx-2 bg-{{ isolatedColor }} rounded-full"></div>
        <p>Isolated</p>
      </div>
    </div>
    <div *ngIf="showLegend" class="h-8 mx-3 border-l border-gray-600"></div>
    <button *ngIf="showTutorialButton" (click)="openTutorialQuestion()">
      <img
        src="/assets/img/information-icon.svg"
        alt="Information icon"
        class="mx-3" />
    </button>
  </div>
  <!-- Mobile -->
  <div *ngIf="isMobile && !is3dOpen" class="flex items-center">
    <button
      *ngIf="showLegend"
      (click)="openLegend()"
      class="btn-white-reverse w-min h-8 align-middle">
      Legend
    </button>
    <button *ngIf="showTutorialButton" (click)="openTutorialQuestion()">
      <img
        src="/assets/img/information-icon.svg"
        alt="Information icon"
        class="ml-2" />
    </button>
  </div>
</div>
