<div class="w-full rounded-2.5xl">
  <!-- Header -->
  <div
    class="border-b border-gray-300 text-center"
    [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <h1 [ngClass]="{ 'text-xl': isMobile }" [innerHTML]="title"></h1>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <h4>The following seats have restrictions, check for them:</h4>
    <ul
      style="
        list-style-type: disc;
        padding-left: 20px;
        max-height: 400px;
        overflow-y: auto;
      ">
      <li *ngFor="let seat of seats">
        Section: {{ seat.section.split('_')[1] }} Row: {{ seat.seat_row }} Seat:
        {{ seat.seat }}
        <ul style="list-style-type: circle; padding-left: 20px">
          <ng-container *ngFor="let esd of seat.definitions">
            @if (esd.restriction) {
              <li>
                {{ esd.description }}
              </li>
              <br />
            }
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
  <div
    class="border-t border-gray-300 text-center"
    [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <div class="flex justify-center">
      <button
        (click)="close()"
        class="btn-main"
        [ngClass]="isMobile ? 'mr-3' : 'mr-6'">
        Close
      </button>
      <button
        (click)="continue()"
        class="btn-main"
        [ngClass]="isMobile ? 'mr-3' : 'mr-6'">
        Continue
      </button>
    </div>
  </div>
</div>
