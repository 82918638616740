import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { SeatManagementService } from 'src/app/digital-venue/services/seat-management.service';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
import { ModalsService } from '../../shared/modals/modals.service';
import { Seat, SeatsBySection } from '../../shared/models';
import {
  AvailabilityService,
  CartService,
  UtilitiesService,
} from '../../shared/services';
import { UpsellsService } from '../upsells/upsells.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  modalService = inject(ModalsService);

  isMobile: boolean;
  @Output() checkoutFunc = new EventEmitter();

  Object = Object;
  sectionsListOpen: string[] = [];

  get seats(): Seat[] {
    // console.log('selectedSeats: ', this.cartService.selectedSeats);
    return Object.values(this.cartService.selectedSeats);
  }
  get totalSeatsPrice() {
    return this.cartService.selectedSeatsTotalPrice;
  }

  minSeatsLimit: number;
  maxSeatsLimit: number;

  seatsBySection: SeatsBySection[];
  // get seatsBySection(): SeatsBySection[] {
  //   console.log('selected Seats by Section: ', this.cartService.sortedSelectedSeatsBySectionArray[0].seats);
  //   return Object.values(this.cartService.sortedSelectedSeatsBySectionArray);
  // };

  constructor(
    private cartService: CartService,
    private dvmService: DvmService,
    private availabilityService: AvailabilityService,
    private utilitiesService: UtilitiesService,
    private upsellsService: UpsellsService,
    private seatManagementService: SeatManagementService,
    private tutorialService: TutorialService
  ) {}

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.minSeatsLimit = this.availabilityService.minSeatsLimit;
    this.maxSeatsLimit = this.availabilityService.maxSeatsLimit;

    this.cartService.sortedSelectedSeatsBySectionArray$.subscribe({
      next: response => {
        let sortedSeatsBySectionAndRowArray =
          this.utilitiesService.addRowDsitribution(response);

        sortedSeatsBySectionAndRowArray.forEach(section => {
          section.rows = Object.values(section.rows);
          section.rows.forEach(row => {
            row.seats = Object.values(row.seats).sort((a: Seat, b: Seat) => {
              return +a.seat - +b.seat;
            });
          });
        });

        this.seatsBySection = sortedSeatsBySectionAndRowArray;

        // console.log('Seats By Section: ', this.seatsBySection);
      },
      error: error => console.error(),
    });

    //* UPSELLS
    // Setting initial seats for Upsells to show next benefits list
    setTimeout(() => {
      this.upsellsService.totalCartAndFiltersSeats$.next(this.minSeatsLimit);
      this.upsellsService.checkCartSeatsForUpsellsList(this.minSeatsLimit);
      // this.upsellsService.totalSeats.next(this.seats.length);
    }, 300);

    const tutorial$ = this.tutorialService.tutorial$.subscribe(
      tutorialConfig => {
        if (tutorialConfig.activeStepCommand === 'activateCheckoutButton') {
          this.minSeatsLimit = 1;
          this.maxSeatsLimit = Infinity;
        }

        if (tutorialConfig.isTutorialEnded) {
          this.minSeatsLimit = this.availabilityService.minSeatsLimit;
          this.maxSeatsLimit = this.availabilityService.maxSeatsLimit;
        }
      }
    );
    this.dvmService.viewerSubscriptions.push(tutorial$);
  }

  checkoutMob() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.checkoutFunc.emit();
  }

  removeSectionSeatsFromSelectedSeats(section) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.seatManagementService.unselectSelectedSeats(
      Object.values(section.seats).map((v: Seat) => {
        return { id: v.id, original_id: v.original_id };
      })
    );
  }

  removeOneFromSelectedSeats(seat: Seat) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.seatManagementService.unselectSelectedSeats([
      { id: seat.id, original_id: seat.original_id },
    ]);
  }

  removeAllFromSelectedSeats() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.seatManagementService.unselectAllSelectedSeats();
  }

  goToSeatmap(sectionId) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    // console.log('Section map ID: ', sectionId);
    this.dvmService.loadMap(sectionId);
  }

  isAda(seatId) {
    if (seatId && typeof seatId === 'string') {
      const sectionId = seatId.split('-')[0];
      const adaSeats = Object.values(
        this.availabilityService.seatsAvailability[sectionId]
      )
        .filter(a => a.isAda)
        .map(a => a.id);
      return adaSeats.includes(seatId);
    }
    return false;
  }

  toggleSectionList(sectionId: string, element?: HTMLElement) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    if (this.sectionsListOpen.includes(sectionId)) {
      this.sectionsListOpen = this.sectionsListOpen.filter(
        item => item !== sectionId
      );
      console.log('Remove List Open: ', this.sectionsListOpen);
      if (element) {
        element.classList.replace('rotate', 'no-rotate');
      }
    } else {
      this.sectionsListOpen.push(sectionId);
      console.log('Add List Open: ', this.sectionsListOpen);
      if (element) {
        element.classList.replace('no-rotate', 'rotate');
      }
    }
  }

  // FIXME: Remove and move to data config client
  load3dView(seatId) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    let panoId = seatId;
    if (seatId.includes('S_Bleacher') || seatId.includes('S_BLCHADA')) {
      panoId = 'S_Bleacher';
    }
    if (
      this.dvmService.viewer3d &&
      this.dvmService.viewer3d.getViewId() === panoId
    ) {
      return;
    }
    this.dvmService.load3dView(panoId);
  }

  // FIXME: Remove and move to data config client
  isGASection(sectionId) {
    return sectionId == 'S_BLCHADA' || sectionId === 'S_Bleacher';
  }

  /**
   * Open seat definitions
   * @param {string} id
   * @version 1.3.1
   * @since 1.3.1
   */
  openSeatDefinitionModal(id: string): void {
    const seatInfo = this.availabilityService.getSeatByIdFromAvailability(id);
    const dvmSeat = this.dvmService.viewer.getNodeById(id);
    this.modalService.openSeatDefinitionModal(seatInfo, dvmSeat.original_id);
  }

  /**
   * Returns boolean to know if seat has definitions or not, in this moments only is used on template
   * @param {string} id
   * @version 1.3.1
   * @since 1.3.1
   */
  hasSeatDefinition(id: string): boolean {
    const seatInfo = this.availabilityService.getSeatByIdFromAvailability(id);
    return seatInfo.seatDefinition.length > 0;
  }

  trackByFn(index: number, section: any) {
    return section.original_id;
  }
}
