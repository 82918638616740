import { Component, OnInit } from '@angular/core';
import { AvailabilityService, UtilitiesService } from 'src/app/shared/services';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
import { GeneralAvailability } from '../../../shared/models';
import { BestAvailableService } from '../../services/best-available.service';
import { DvmService } from '../../services/dvm.service';
import { Popover3dviewService } from '../../services/popover-3dview.service';

@Component({
  selector: 'app-section-popover',
  templateUrl: './section-popover.component.html',
  styleUrls: ['./section-popover.component.scss'],
})
export class SectionPopoverComponent implements OnInit {
  isMobile: boolean;
  sectionData: GeneralAvailability;
  tooltipData: { section: string; img: string; type: string } = {
    img: '',
    section: '',
    type: '',
  };
  includedSectionForBA: boolean;

  constructor(
    private dvmService: DvmService,
    private availabilityService: AvailabilityService,
    private utilitiesService: UtilitiesService,
    private bestAvailableService: BestAvailableService,
    private popover3dview: Popover3dviewService,
    private tutorialService: TutorialService
  ) {}

  get priceRange() {
    let priceRange = this.sectionData.priceRange[0].value.toString(10);
    if (!this.sectionData) {
      return '';
    }
    if (
      this.sectionData.priceRange[0].value !=
      this.sectionData.priceRange[1].value
    ) {
      priceRange = `${this.sectionData.priceRange[0].value} - ${this.sectionData.priceRange[1].value}`;
    }
    return priceRange;
  }

  get adaQuantity() {
    // TODO: change this access to mmcID
    if (
      this.availabilityService.sectionsAvailability[
        this.sectionData.sectionID
      ] &&
      this.availabilityService.sectionsAvailability[this.sectionData.sectionID]
        .availableQuantityAda
    ) {
      return this.availabilityService.sectionsAvailability[
        this.sectionData.sectionID
      ].availableQuantityAda;
    } else if (
      this.availabilityService.sectionsOnlyAdas[this.sectionData.sectionID] &&
      this.availabilityService.sectionsOnlyAdas[this.sectionData.sectionID]
        .availableQuantityAda
    ) {
      return this.availabilityService.sectionsOnlyAdas[
        this.sectionData.sectionID
      ].availableQuantityAda;
    }
    return 0;
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.includedSectionForBA =
      this.bestAvailableService.selectedSectionsForBA.includes(
        this.sectionData.sectionID
      );

    this.tooltipData.section = this.sectionData.mmcID;

    // this.viewer.getThumbnail(this.tooltipdata.section)
    let thumbSection = this.sectionData.sectionID;
    if (this.tooltipData.section === 'S_BLCHADA') {
      thumbSection = 'S_Bleacher';
    }

    this.dvmService.viewer
      .getThumbnail(thumbSection)
      .then(img => {
        this.tooltipData.img = (img as unknown as HTMLImageElement).src;
      })
      .catch(err => {
        console.error(err);
      });
  }

  load3dView() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    const tooltip: HTMLElement = document.querySelector('#tooltip');
    tooltip.removeAttribute('data-show');
    this.popover3dview.deleteTimer();
    this.dvmService.load3dView(this.sectionData.sectionID);
  }

  onEnter(event: MouseEvent) {
    this.popover3dview.deleteTimer();
  }

  onLeave(event: MouseEvent) {
    this.popover3dview.setTimer();
  }

  openSeatMap() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.dvmService.loadMap(`${this.sectionData.sectionID}`);
    this.popover3dview.setTimer();
  }

  selectSectionForBA() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    if (!this.includedSectionForBA) {
      this.bestAvailableService.selectSectionForBA(this.sectionData.sectionID);
    } else {
      this.bestAvailableService.unselectSectionForBA(
        this.sectionData.sectionID
      );
    }
    this.includedSectionForBA =
      this.bestAvailableService.selectedSectionsForBA.includes(
        this.sectionData.sectionID
      );
  }
}
