<div *ngIf="validTransaction" class="h-full overflow-y-auto">
  <div class="w-full bg-gray-100 border-b border-gray-300">
    <div
      [ngClass]="
        isMobile
          ? 'w-full px-5 py-7'
          : 'max-w-max px-20 py-14 pb-7 3xl:pb-14 mx-auto flex'
      ">
      <div
        class="text-gray-700"
        [ngClass]="isMobile ? 'text-center' : 'w-352 mr-10'">
        <h2 [ngClass]="{ 'text-base': isMobile }">Summary</h2>
        <h1 [ngClass]="isMobile ? '' : 'text-4xl 3xl:text-5xl'">
          Thanks for your purchase
        </h1>
      </div>
    </div>
  </div>

  <div
    [ngClass]="
      isMobile
        ? 'w-full px-5 py-7'
        : 'max-w-max px-20 py-14 pt-7 3xl:pt-14 mx-auto flex'
    ">
    <div [ngClass]="isMobile ? '' : 'w-160 2xl:w-176'">
      <h3
        class="mb-4 font-bold text-main-dark"
        [ngClass]="{ 'text-center': isMobile }">
        Your Order Details
      </h3>

      <!-- DATA BOX -->
      <div
        class="box-white shadow-lg"
        [ngClass]="isMobile ? 'px-4 py-2 mb-10' : 'px-6 py-4'">
        <!-- Seats recap -->
        <h4
          *ngFor="let seat of seatsByPrice"
          class="font-bold text-main-dark"
          [ngClass]="{ 'text-center': isMobile }">
          <span class="underline">{{ seat.quantity }} Seats</span> x
          <span class="underline">{{
            transaction.transaction.buyerTypeName
          }}</span>
          at
          <span class="text-red">${{ seat.price | number: '1.2-2' }}</span>
        </h4>

        <!-- Seats table -->
        <div class="w-full" [ngClass]="isMobile ? 'mt-2 mb-7' : 'mt-4 mb-10'">
          <table class="w-full table-fixed border-separate">
            <tr
              *ngFor="let section of seatsForTable"
              [ngClass]="{ 'mb-3 flex flex-wrap': isMobile }">
              <td class="td" [ngClass]="isMobile ? 'w-full mb-1' : 'w-16'">
                <div class="flex justify-center items-center">
                  <img
                    src="/assets/img/chair-blue-icon.svg"
                    alt="main chair icon"
                    class="mr-1" />
                  <p class="font-bold text-center text-main">
                    {{ section.totalSectionSeats }}
                  </p>
                </div>
              </td>
              <td
                class="td"
                [ngClass]="isMobile ? 'w-full mb-1 flex justify-center' : ''">
                <p class="flex items-center">
                  Section
                  <span class="ml-2 font-bold text-main">{{ section.id }}</span>
                </p>
              </td>
              <td class="p-0" [ngClass]="isMobile ? 'w-full' : ''">
                <div *ngFor="let row of section.rows" class="td-lines flex">
                  <p class="mr-10">
                    Row<span class="ml-2 font-bold text-main">{{
                      row.id
                    }}</span>
                  </p>
                  <p>
                    Seats<span class="ml-2 font-bold text-main">{{
                      row.seatsRanges.join(', ')
                    }}</span>
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <!-- Cart summary -->
        <div class="w-full" [ngClass]="isMobile ? 'mb-7' : 'flex mb-10'">
          <div
            class="m-auto border-gray-200"
            [ngClass]="isMobile ? 'pb-3 border-b' : 'w-2/3 pr-3 border-r'">
            <div class="m-auto">
              <h5
                class="mb-2 font-bold"
                [ngClass]="isMobile ? 'text-center' : 'text-right'">
                Your adjusted seat amount is listed below:
              </h5>
              <div class="text-gray-500">
                <div
                  [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                  <h5>Seats:</h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                    [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                    ${{
                      transaction?.transaction.tdc_transaction.totalPriceSeats
                        | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div
                  [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                  <h5 class="font-normal">Total ticket fees:</h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                    [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                    ${{
                      transaction?.transaction.tdc_transaction.serviceCharges
                        | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div
                  *ngIf="
                    transaction?.transaction.tdc_transaction.amusementTax > 0
                  "
                  [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                  <h5 class="font-normal">Amusement tax (12% of seat cost):</h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                    [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                    ${{
                      transaction?.transaction.tdc_transaction.amusementTax
                        | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div
                  *ngIf="
                    transaction?.transaction.tdc_transaction.hasInsuranceRainOut
                  "
                  [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                  <h5 class="font-normal text-right">Rainout Insurance:</h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                    [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                    ${{
                      transaction?.transaction.tdc_transaction
                        .insueranceRainOutPrice | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div *ngIf="acquiredUpsells">
                  <div
                    *ngFor="let upsell of acquiredUpsells"
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5 class="text-right">
                      <span class="mr-1 font-normal">By purchasing</span
                      >{{ upsell.goal }} tickets
                      <span class="mx-1 font-normal inline-block"
                        >you receive:</span
                      >{{ upsell.name }}.
                    </h5>
                    <div class="min-w-max">
                      <h5
                        class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                        [ngClass]="
                          isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'
                        ">
                        BENEFIT
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [ngClass]="isMobile ? 'pt-3' : 'w-1/3 pl-3 m-auto'">
            <div class="text-center">
              <h4 class="font-bold">Ticket Total:</h4>
              <h4 class="font-bold">
                ${{
                  transaction?.transaction.tdc_transaction.balance
                    | number: '1.2-2'
                }}
              </h4>
            </div>
          </div>
        </div>

        <!-- Tickets email -->
        <div [ngClass]="isMobile ? 'm-2' : 'mb-2'">
          <h4 class="mb-2 font-bold text-main-dark">MLB Ballpark App</h4>
          <h5 class="font-normal">
            {{ configurationService.configuration.clientName }} tickets are
            available exclusively as mobile tickets via the free MLB Ballpark
            app. To view and use tickets, log in to your existing MLB.com
            account or create an account in the MLB Ballpark app with the email
            used to purchase your ticket(s). Detailed instructions on using the
            MLB Ballpark app, including email verification, viewing tickets, and
            forwarding tickets, are available at
            <a
              [href]="configurationService.configuration.clientBallParkUrl"
              target="_blank"
              >{{ configurationService.configuration.clientBallParkText }}</a
            >.
          </h5>
        </div>
      </div>
    </div>

    <div [ngClass]="isMobile ? 'w-full' : 'w-160 2xl:w-176 ml-8 2xl:ml-10'">
      <h3
        class="mb-4 font-bold text-red"
        [ngClass]="{ 'text-base text-center': isMobile }">
        You might also be interested in:
      </h3>

      <div
        class="text-center text-white flex"
        [ngClass]="{ 'flex-wrap justify-center': isMobile }">
        <!-- ADS -->
        <a
          *ngIf="summaryLink"
          [href]="summaryLink?.link"
          target="_blank"
          class="box-white h-80 p-3 text-white bg-main-dark flex flex-col justify-between items-center shadow-lg"
          [ngClass]="isMobile ? 'w-60 mb-5' : 'w-1/3 2xl:p-5 mr-4 2xl:mr-6'">
          <div>
            <img
              *ngIf="!noImage"
              [src]="summaryLink?.url_image"
              class="max-h-32 m-auto mb-2"
              alt="{{ summaryLink?.title }}" />
            <div class="m-auto">
              <p class="max-h-14 mb-2 font-normal overflow-y-auto">
                {{ summaryLink?.title }}
              </p>
              <!-- <h5 class="font-normal">{{ team.city }}</h5> -->
              <!-- <h1 class="font-normal">{{ team.name }}</h1> -->
            </div>
          </div>
          <div class="mb-3 flex flex-col items-center">
            <hr class="w-24 mb-4 border-white opacity-20" />
            <h4 class="font-bold">{{ summaryLink?.name }}</h4>
            <!-- <h4 class="font-bold">Other games against the same opponent</h4> -->
          </div>
        </a>
        <a
          [href]="configurationService.configuration.mlbShopUrl"
          target="_blank"
          class="box-white h-80 p-3 text-main-dark flex flex-col justify-between items-center shadow-lg"
          [ngClass]="isMobile ? 'w-60 mb-5' : 'w-1/3 2xl:p-5 mr-4 2xl:mr-6'">
          <div class="m-auto">
            <img
              src="{{ configurationService.configuration.summaryPage.image1 }}"
              alt="mechandise t-shirts image" />
          </div>
          <div class="mb-3 flex flex-col items-center">
            <hr class="w-24 mb-4" />
            <h4 class="font-bold">
              {{ configurationService.configuration.clientName }} Merchandise
            </h4>
          </div>
        </a>
        @if (
          configurationService.configuration.merchandiseWebUrl &&
          configurationService.configuration.summaryPage.image2
        ) {
          <a
            [href]="configurationService.configuration.merchandiseWebUrl"
            target="_blank"
            class="box-white h-80 p-3 text-main-dark text-white bg-cover flex flex-col justify-end items-center shadow-lg"
            [ngStyle]="{
              'background-image':
                configurationService.configuration.summaryPage.image2,
            }"
            style="background-position: -150px; background-repeat: no-repeat"
            [ngClass]="isMobile ? 'w-60 mb-5' : 'w-1/3 2xl:p-5'">
            <div class="mb-4 flex flex-col items-center">
              <hr class="w-24 mb-4 border-white opacity-20" />
              <h4 class="font-bold text-white">
                {{ configurationService.configuration.clientName }}
              </h4>
              <h4 class="font-bold text-white">Suites</h4>
            </div>
          </a>
        }
      </div>
    </div>
  </div>
</div>
