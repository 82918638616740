<div class="pb-11">
  <div
    *ngIf="!isMobile; else filtersMobile"
    id="filters"
    class="w-100 flex items-center">
    <div class="container max-w-screen-lg flex flex-col mx-auto">
      <div class="w-100" id="form">
        <div class="flex justify-between">
          <!-- select month -->
          <select
            class="selector-filter"
            [(ngModel)]="formSelects.month"
            (change)="calculateDayPerMonth($event)"
            ngDefaultControl>
            <option value="none">Month</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <!-- select day -->
          <select
            class="selector-filter"
            [(ngModel)]="formSelects.day"
            ngDefaultControl>
            <option value="none">Day</option>
            <option *ngFor="let day of dayPerMonth" value="{{ day + 1 }}">
              {{ day + 1 }}
            </option>
          </select>
          <!-- select opponent -->
          <select
            class="selector-filter"
            [(ngModel)]="formSelects.opponent"
            ngDefaultControl>
            <option value="none">Opponent</option>
            <option
              *ngFor="let rival of opponentList"
              value="{{ rival.description }}">
              {{ rival.description }}
            </option>
          </select>
        </div>
        <div class="mt-3 flex justify-end">
          <!-- select buttons -->
          <button
            type="button"
            class="button-filter reset"
            (click)="resetForm()">
            Clear All
          </button>
          <button
            type="button"
            class="button-filter submit"
            (click)="onSubmitFilters()">
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- FILTERS MOBILE START -->
  <ng-template #filtersMobile>
    <div id="filters" class="w-100 flex items-center">
      <div
        class="container max-w-screen-lg flex flex-col mx-auto"
        id="form-mobile">
        <div class="w-80 mx-auto flex flex-row justify-center">
          <!-- selector month-->
          <select
            class="selector-filter"
            [(ngModel)]="formSelects.month"
            (change)="calculateDayPerMonth($event)"
            ngDefaultControl>
            <option value="none">Month</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <!-- selector day -->
          <select
            class="selector-filter"
            [(ngModel)]="formSelects.day"
            ngDefaultControl>
            <option value="none">Day</option>
            <option *ngFor="let day of dayPerMonth" value="{{ day + 1 }}">
              {{ day + 1 }}
            </option>
          </select>
        </div>
        <div class="w-80 flex justify-center mx-auto mt-2">
          <select
            class="selector-filter full"
            [(ngModel)]="formSelects.opponent"
            ngDefaultControl>
            <option value="none">Opponent</option>
            <option
              *ngFor="let rival of opponentList"
              value="{{ rival.description }}">
              {{ rival.description }}
            </option>
          </select>
        </div>
        <div class="w-80 mx-auto flex flex-row mt-2">
          <!-- select buttons -->
          <button
            type="button"
            class="button-filter reset"
            (click)="resetForm()">
            Clear All
          </button>
          <button
            type="button"
            class="button-filter submit"
            (click)="onSubmit()">
            Search
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- FILTERS MOBILE END-->
  <div class="container max-w-screen-xl flex flex-col mx-auto">
    <div id="events" class="">
      <div class="text-center mt-5" style="color: #173482">
        <span class="text-xs"
          >(All Times {{ this.timeZone }}. Subject to change.)</span
        >
      </div>
      <div class="mt-5" *ngFor="let item of events.data">
        <app-sale-event
          (notifySelected)="getSelection($event)"
          [event]="item"></app-sale-event>
      </div>
    </div>
    <app-pagination
      [total_items_in_db]="totalItemsInDb"
      [items_per_page]="itemsPerPage"
      [currentPage]="currentPage"
      (changepage)="onChangePage($event)"></app-pagination>
  </div>
</div>
