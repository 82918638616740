import { MapViewerNode } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { createPopper, Placement } from '@popperjs/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AvailabilityService, UtilitiesService } from '../../shared/services';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { SeatPopoverComponent } from '../popovers/seat-popover/seat-popover.component';
import { SectionPopoverComponent } from '../popovers/section-popover/section-popover.component';
import { BestAvailableService } from '../services/best-available.service';
import { DvmService } from '../services/dvm.service';
import { Popover3dviewService } from '../services/popover-3dview.service';
import { SeatManagementService } from '../services/seat-management.service';

@Injectable()
export class HoverHandler {
  tooltip: HTMLElement;
  view3DId: string;
  descriptions;
  sectionOffsets: Record<string, number[]> = {};

  constructor(
    private dvmService: DvmService,
    private seatManagementService: SeatManagementService,
    private bestAvailableService: BestAvailableService,
    private availabilityService: AvailabilityService,
    private configurationService: ConfigurationService,
    private popover3dview: Popover3dviewService,
    private utilsService: UtilitiesService,
    private modalsService: ModalsService
  ) {
    // CLICK subscribe

    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        const subscription = viewer.subscribe('enter', this.handle.bind(this));
        this.dvmService.viewerSubscriptions.push(subscription);
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Viewer.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      },
    });
    this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  private handle(obj) {
    if (
      obj.nodes.length &&
      (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')
    ) {
      this.popover3dview.deleteTimer();

      const node = obj.nodes[0] as MapViewerNode;
      // Receive tooltip HTML Element could be done better todo
      this.tooltip = document.querySelector('#tooltip');
      if (!this.utilsService.isMobile) {
        this.dvmService.viewer.hover(node.id);
      }
      this.popover3dview.currentView = node.id;
      // Create content HTML Element, clean previous popover and append
      this.dvmService.popoverPlacement.clear();

      let offset;

      if (this.utilsService.isMobile && node.type == 'general_admission') {
        return;
      }

      if (
        (node.type === 'section' || node.type == 'general_admission') &&
        obj.instance.getMapId() ===
          this.configurationService.configuration.dvmData.mapId
      ) {
        const commponentRef = this.dvmService.popoverPlacement.createComponent(
          SectionPopoverComponent
        );

        let tdcId = node.id;
        let mmcId = node.original_id;
        const dvmOffset =
          this.configurationService.configuration.dvmData.sectionOffsets[tdcId];
        offset = dvmOffset ? dvmOffset : [0, -10];

        if (
          Object.keys(this.availabilityService.sectionsOnlyAdas).includes(tdcId)
        ) {
          commponentRef.instance.sectionData =
            this.availabilityService.sectionsOnlyAdas[tdcId];
          commponentRef.instance.sectionData.mmcID = mmcId;
          commponentRef.instance.tooltipData.type = node.type;
        } else {
          commponentRef.instance.sectionData =
            this.availabilityService.sectionsAvailability[tdcId];
          commponentRef.instance.tooltipData.type = node.type;
          commponentRef.instance.sectionData.mmcID = mmcId;
        }
      } else if (node.type === 'seat') {
        offset = [0, 6];
        if (this.utilsService.isMobile) return;
        const commponentRef =
          this.dvmService.popoverPlacement.createComponent(
            SeatPopoverComponent
          );
        commponentRef.instance.seatData =
          this.availabilityService.getSeatByIdFromAvailability(node.id);
      }

      // Calculate popover placement
      let placement: Placement;
      const nodeDomCenter = this.dvmService.viewer.fromSceneToDom([
        node.aabb[0],
        node.aabb[1],
      ]);

      const nodeHalfHeight = node.aabb[3] / 2;
      const nodeVerticalCenter = nodeDomCenter[1] + nodeHalfHeight;
      const seatSelectionHalfHeight =
        document.getElementById('seat-selection').offsetHeight / 2;

      if (nodeVerticalCenter > seatSelectionHalfHeight) {
        placement = 'top';
      } else {
        placement = 'bottom';
      }

      // Initiate popover
      createPopper(node, this.tooltip, {
        placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            },
          },
        ],
      });

      // Display popover
      this.tooltip.setAttribute('data-show', '');
    }
  }
  sectionPopoverContent(node) {
    return node.id;
  }
  seatPopoverContent(node) {
    return node.id;
  }
}
