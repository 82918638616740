import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { PrivateSellingService } from '../shared/services/private-selling.service';
import { OktaContainerComponent } from './components';
import { RedirectPageComponent } from './components/redirect-page/redirect-page.component';
import { OktaIntegrationComponent } from './okta-integration.component';

const oktaAuth = new OktaAuth({
  issuer: `${environment.oktaData.issuer}`,
  clientId: `${environment.oktaData.clientId}`,
  redirectUri: window.location.origin + '/login',
});

@NgModule({
  declarations: [
    OktaIntegrationComponent,
    OktaContainerComponent,
    RedirectPageComponent,
  ],
  imports: [CommonModule, OktaAuthModule],
  exports: [OktaContainerComponent],
  providers: [
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    PrivateSellingService,
  ],
})
export class OktaIntegrationModule {}
