import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BestAvailableService } from '../../../digital-venue/services/best-available.service';
import { DvmService } from '../../../digital-venue/services/dvm.service';
import { SeatManagementService } from '../../../digital-venue/services/seat-management.service';
import { AvailabilityService, UtilitiesService } from '../../services';
import { ConfigurationService } from '../../services/configuration.service';
import { ModalsService } from '../modals.service';

@Component({
  selector: 'app-ga-modal',
  templateUrl: './ga-modal.component.html',
  styleUrls: ['./ga-modal.component.scss'],
})
export class GaModalComponent implements OnInit {
  isMobile: boolean;

  title: string;
  subtitle?: string;
  content: string;
  closeBtnName: string;
  closeFunction: () => void;
  acceptBtnName: string;
  acceptFunction: () => void;
  isAda: boolean;

  gaNumSeats: number = 0;
  seatsAvailable: string[];

  constructor(
    public bsModalRef: BsModalRef,
    private availabilityService: AvailabilityService,
    private seatManagementService: SeatManagementService,
    private configurationService: ConfigurationService,
    private modalsService: ModalsService,
    private utilitiesService: UtilitiesService,
    private dvmService: DvmService,
    private bestAvailableService: BestAvailableService
  ) {}

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
    const selected = this.bestAvailableService.lastSelectedSection;
    let section = selected;

    // // todo parametrize, initially cubs only has bleachers
    // if (
    //   this.configurationService.environment == 'chicagocubs' ||
    //   this.configurationService.environment == 'default'
    // ) {
    //   if (this.isAda) {
    //     section = this.dvmService.bleacherAdaSection;
    //   } else {
    //     section = this.dvmService.bleacherSection;
    //   }
    // }
    this.availabilityService.getSeatAvailability([section]).subscribe(data => {
      let adaAvailability = Object.values(
        this.availabilityService.seatsAvailability[section]
      )
        .filter(a => a.isAda)
        .map(a => a.id);
      if (this.isAda) {
        this.seatsAvailable = adaAvailability;
      } else {
        this.seatsAvailable = data.filter(a => !adaAvailability.includes(a));
      }
    });
  }

  // on accept we randomly select some seats and add to selectSeats for cart
  accept() {
    this.bsModalRef.hide();

    if (this.seatsAvailable?.length >= this.gaNumSeats) {
      this.seatManagementService.selectSeatsForCart(
        this.seatsAvailable?.slice(0, this.gaNumSeats)
      );
    } else {
      const modalData = {
        title: 'ERROR',
        content: 'Not enough seats are available in this section.',
        acceptBtnName: 'CLOSE',
      };
      this.modalsService.openModal(modalData);
    }
  }

  close() {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }
}
