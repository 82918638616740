import { Tutorial } from '../models';
import {
  Configuration,
  Custom,
  DvmData,
  DvmStyles,
  LegalTextData,
  Theme,
} from '../models/configuration.model';

const custom: Custom = {
  paymentPage: {
    captureAddress: true,
    capturePhone: false,
  },
};

const theme: Theme = {
  'default-color': '13 43 86',
  'main-color': '13 43 86',
  'secondary-color': '25 149 255',
  'tertiary-color': '242 246 250',
  'accent-color': '189 48 57',
  red: '189 48 57',
  success: '86 163 67',
  'success-off-white': '236 246 232',
};

const dvmData: DvmData = {
  venueId: 'nam-us-10170-redsox',
  mapId: 'main_venuemap',
  miniMapId: 'main_venuemap_minimap',
  miniMapEnabled: true,
  gaSectorsIdArray: [],
  gaAdaIdArray: [],
  sectionOffsets: {},
  limitedZoom: true,
};

const dvmStyles: DvmStyles = {
  'seat-available-none-fill': '25 149 255',
  'seat-available-none-stroke': '0 90 156',
  'seat-available-disabled-fill': '25 149 255',
  'seat-available-disabled-stroke': '0 90 156',
  'seat-unavailable-none': '214 214 214',
  'seat-available-pending': '230 145 56',
  'section-available-normal-fill': '25 149 255',
  'section-available-normal-stroke': '0 90 156',
  'section-available-normal-group1-fill': '255 77 77',
  'section-available-normal-group1-stroke': '255 77 77',
  'section-available-hover-fill': '0 90 156',
  'section-available-hover-stroke': '255 255 255',
  'section-selected-normal-fill': '122 64 185',
  'section-selected-normal-stroke': '122 64 185',
  'section-selected-hover-stroke': '255 255 255',
};

const seatSelectionDesktopTutorial: Tutorial = {
  sectionAvailabilityIds: ['3071', '3062'],
  availableSeatsQuantity: 240,
  selectedSeatsQuantity: 30,
  allowClose: false,
  doneBtnText: 'End Tutorial',
  nextBtnText: 'Next',
  popoverClass: 'driverjs-theme',
  prevBtnText: 'Back',
  steps: [
    {
      element: '#filters-header',
      command: 'activateMobileFiltersTab',
      popover: {
        description:
          '1. Start by selecting the total number of seats to purchase.',
      },
    },
    {
      element: '#filters-slider',
      command: 'activateMobileFiltersTab',
      popover: {
        description:
          '2. Use the slider to set your desired price range per ticket.',
      },
    },
    {
      element: '#filters-adas',
      command: 'activateMobileFiltersTab',
      popover: {
        description:
          '3. If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.',
        onNextClickMethod: 'loadTopViewMapOnNextClick',
      },
    },
    {
      element: '#map-container',
      popover: {
        description:
          '4. Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.',
        side: 'left',
        align: 'center',
        onNextClickMethod: 'showSectionPopoverOnNextClick',
      },
    },
    {
      element: '#tooltip',
      command: 'activateMobileCartTab',
      popover: {
        description:
          '5. Hover over a section to preview a view from that section. Click “Open Seat Map” to manually select seats in that section.',
        align: 'center',
        onPrevClickMethod: 'hidePopoverOnPrevClick',
        onNextClickMethod: 'loadSeatMapOnNextClick',
      },
    },
    {
      element: '#map-container',
      command: 'setSeatsInCart',
      popover: {
        description:
          '6. Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat individually or hold Ctrl + click and drag to select a group of seats.',
        side: 'left',
        align: 'center',
        onPrevClickMethod: 'showSectionPopoverAfterTopviewMapLoadOnPrevClick',
        onNextClickMethod: 'loadTopViewMapOnNextClick',
      },
    },
    {
      element: '#cart-list',
      popover: {
        description:
          '7. Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.',
        onPrevClickMethod: 'loadSeatMapOnPrevClick',
      },
    },
    {
      element: '#summary',
      popover: {
        description:
          '8. When you are ready to purchase, click “Go to Checkout.”',
      },
    },
  ],
};

const seatSelectionMobileTutorial: Tutorial = {
  ...seatSelectionDesktopTutorial,
  steps: [
    ...seatSelectionDesktopTutorial.steps.slice(0, 4),
    {
      ...seatSelectionDesktopTutorial.steps[4],
      popover: {
        ...seatSelectionDesktopTutorial.steps[4].popover,
        description:
          '5. Click a section to preview a view from that section. Click “Select“ to find your seats in that particular section or click “Open Seat Map“ to manually select seats in that section.',
      },
    },
    {
      ...seatSelectionDesktopTutorial.steps[5],
      popover: {
        ...seatSelectionDesktopTutorial.steps[5].popover,
        description:
          '6. Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat.',
      },
    },
    ...seatSelectionDesktopTutorial.steps.slice(6),
  ],
};

const checkoutTutorial: Tutorial = {
  ...seatSelectionDesktopTutorial,
  steps: [
    {
      element: '#ticket-details',
      popover: {
        description: '1. Review  your ticket information.',
      },
    },
    {
      element: '#group-outing',
      popover: {
        description:
          '2. Enhance your group outing with an optional upgraded ticket.',
      },
    },
    {
      element: '#cart-summary',
      popover: {
        description:
          '3. Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.',
      },
    },
  ],
};
const clientWebUrl = 'https://www.redsox.com/';
const clientWebText = 'www.redsox.com.';
const clientName = 'Boston Red Sox';
export const BostonRedSoxConfig: Configuration = {
  clientName: 'Boston Red Sox',
  appName: 'Redsox',
  favicon: '/assets/img/redsox/favicon.png',
  logo: '/assets/img/redsox/logo.png',
  phone: '617-226-6835',
  email: 'groupsales@redsox.com',
  paymentMethod: 'braintree',
  rainout: false,

  upgrades: true,
  clientWebUrl,
  clientWebText: 'www.redsox.com.',
  clientBallParkUrl:
    'https://www.mlb.com/redsox/apps/ballpark?partnerId=redirect-chc-ballparkapp',
  clientBallParkText: 'redsox.com/BallparkApp',
  mlbShopUrl:
    'https://www.mlbshopeurope.com/en/boston-red-sox/t-25556353+z-9324832-1137929273',
  merchandiseWebUrl: '',
  rainoutInsuranceUrl: '',
  termsAndConditionsUrl:
    'https://www.mlb.com/redsox/tickets/terms-and-conditions',
  analytics: '',
  adas: true,
  theme,
  dvmData,
  dvmStyles,
  tutorial: {
    seatSelection: {
      desktop: seatSelectionDesktopTutorial,
      mobile: seatSelectionMobileTutorial,
    },
    checkout: { desktop: checkoutTutorial, mobile: checkoutTutorial },
  },
  custom,
  extraCheckbox: {
    active: true,
    required: true,
    text:
      '<p>Broker Policy</p>' +
      "<p>Red Sox tickets are revocable licenses and the Red Sox may revoke tickets for any reason, at management's discretion. These ticket licenses do not confer any property right to the ticket holder</p>" +
      '<p>Ticket holders are advised that the Red Sox may cancel or revoke tickets for certain conduct including, but not limited to, the following:</p>' +
      '<p>Any form of fraudulent activity;</p>' +
      '<p>The purchase of tickets for the purpose and intent of reselling the tickets on the secondary market;</p>' +
      '<p>Use of tickets for sweepstakes, contests, and/or promotions without the prior written consent from the Red Sox.</p>' +
      '<p>In the event that ticket holders are unable to attend games and need to sell their tickets, the Club recommends that the ticket holders go through approved entities for this purpose. Ticket holders should comply with applicable Massachusetts law in connection with any such resale.</p>',
  },
  summaryPage: {
    image1: '/assets/img/redsox/logo.png',
    image2: 'url(/assets/img/redsox/red-sox-suites.jpeg)',
  },
  get legalText(): LegalTextData {
    return {
      links: {
        termsLink: {
          href: this.termsAndConditionsUrl,
          text: 'Terms and Conditions',
          target: '_blank',
        },
        clubWebLink: {
          href: this.clientWebUrl,
          text: this.clientWebText,
          target: '_blank',
        },
      },
      text: [
        `As part of your ticket purchase, you are required to acknowledge the {termsLink}.
        The Terms and conditions of your ticket and policies applicable to its use are updated to
        address circumstances related to COVID-19, health and safety, and seating pods.
        By clicking the Place Order button, you agree to accept all {termsLink} applicable to ticket purchase, and all related policies.
        Further, due to the COVID-19 pandemic, scheduling may be impacted and games may be canceled,
        rescheduled, postponed or games may be held with limited capacity. Games may also have restricted capacity that prevents the use of specific
        seats. In the event that the game(s) for which the ticket(s) is issued does not occur or you are unable to use the
        specific seat(s) purchased, a refund or credit will be issued according to the refund policy available at {clubWebLink}.`,

        `By selecting the Place Order button you agree to purchase the tickets selected, to accept any restrictions noted, and
        understand you are responsible for any payment needed to complete the purchase. Upon completing your purchase by
        selecting the Place Order button, you acknowledge and agree your credit card will be charged. All sales are final. All
        tickets are subject to the {termsLink}.`,

        `In connection with your purchase, you may receive customer service-related emails from the ${this.clientName}, including
        optional surveys regarding your gameday experience. You also may receive ticket-related commercial email from the
        ${this.clientName}. You may unsubscribe from ticket-related commercial emails at any time.`,
      ],
    };
  },
};
