<title>Authenticating...</title>
<meta name="robots" content="noindex, nofollow" />
<body class="h-full flex flex-col justify-center items-center gap-y-8">
  <div class="lds-ripple">
    <div></div>
    <div></div>
  </div>
  <h4 class="flex justify-center items-center mx-4 text-center">
    We are authenticating you to your MLB account
  </h4>
</body>
