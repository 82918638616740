<div class="w-full text-center rounded-2.5xl">
  <!-- Header -->
  <div
    class="flex flex-col border-b border-gray-300 gap-y-2"
    [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <h1 [ngClass]="{ 'text-xl': isMobile }" [innerHTML]="title"></h1>
    <h2
      [ngClass]="{ 'text-base': !isMobile, 'text-sm': isMobile }"
      [innerHTML]="subtitle"></h2>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div [ngClass]="isMobile ? 'px-12 py-6' : 'px-24 py-12'">
    <h4 class="font-normal">{{ content }}</h4>
    <label for="ga-seats" class="label">Quantity of seats: </label>
    <input
      type="number"
      min="0"
      [(ngModel)]="gaNumSeats"
      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
      class="input w-32 ml-4 font-bold text-center inline-block"
      name="ga-seats"
      id="ga-seats" /><!-- pl-9 -->
    <div class="flex justify-center" [ngClass]="isMobile ? 'mt-6' : 'mt-12'">
      <button
        *ngIf="closeBtnName"
        (click)="close()"
        class="btn-main-reverse"
        [ngClass]="isMobile ? 'mr-3' : 'mr-6'">
        {{ closeBtnName }}
      </button>
      <button *ngIf="acceptBtnName" class="btn-main" (click)="accept()">
        {{ acceptBtnName }}
      </button>
    </div>
  </div>
</div>
