import { MapViewerNode } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { TutorialService } from '../../shared/services/tutorial.service';
declare var $: any;
// import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class Popover3dviewService {
  popoverTimerTime = 100; // ms
  popoverTimer: ReturnType<typeof setTimeout>;
  currentView: MapViewerNode | string;

  constructor(private tutorialService: TutorialService) {}

  setTimer() {
    // deleting the timer prevents us from coming into component init with a timer asynchronously running
    this.deleteTimer();

    this.popoverTimer = setTimeout(() => {
      this.removeTooltip();
    }, this.popoverTimerTime);
  }

  deleteTimer() {
    if (this.popoverTimer) {
      clearTimeout(this.popoverTimer);
    }
  }

  removeTooltip() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    const tooltip: HTMLElement = document.querySelector('#tooltip');
    tooltip.removeAttribute('data-show');
  }
}
