import { Component, inject } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-forbidden-event-modal',
  templateUrl: './forbidden-event-modal.component.html',
  styleUrls: ['./forbidden-event-modal.component.css'],
})
export class ForbiddenEventModalComponent {
  protected configurationService = inject(ConfigurationService);
}
