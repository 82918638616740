import { MapViewerNode } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router, Event as RouterEvent } from '@angular/router';
import { createPopper } from '@popperjs/core';
import { Config, Driver, driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { BehaviorSubject } from 'rxjs';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { SectionPopoverComponent } from '../../digital-venue/popovers/section-popover/section-popover.component';
import { ModalsService } from '../modals/modals.service';
import {
  GeneralAvailabilityDict,
  SaleEvent,
  SeatAvailabilitySectionDict,
} from '../models';
import { Configuration } from '../models/configuration.model';
import {
  ActiveStepCommand,
  TutorialMethods,
  TutorialMocksConfig,
} from '../models/tutorial.model';
import { ConfigurationService } from './configuration.service';
import { PrivateSellingService } from './private-selling.service';
import { TutorialMocks } from './tutorial-mocks';
import { UtilitiesService } from './utilities.service';

type StorageValue = { alreadyVisited: boolean };
type Storage = Partial<
  Record<keyof Configuration['tutorial'], StorageValue>
> | null;

@Injectable({
  providedIn: 'root',
})
export class TutorialService implements TutorialMethods {
  private tutorialName: keyof Configuration['tutorial'] | null;
  private driver: Driver;
  private tutorialConfig: ReturnType<typeof this.getDefaultTutorialConfig>;
  public tutorial$: BehaviorSubject<
    ReturnType<typeof this.getDefaultTutorialConfig>
  >;

  constructor(
    private modalsService: ModalsService,
    private dvmService: DvmService,
    private configurationService: ConfigurationService,
    private utilitiesService: UtilitiesService,
    private privateSellingService: PrivateSellingService,
    private router: Router
  ) {
    const ROUTES_TUTORIAL_NAME: Array<
      [string, keyof Configuration['tutorial']]
    > = [
      ['/seat-selection', 'seatSelection'],
      ['/checkout', 'checkout'],
    ];

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        const [tutorialName] = ROUTES_TUTORIAL_NAME.flatMap(
          ([route, tutorial]) => {
            if (event.url.includes(route)) {
              return tutorial;
            }
            return [];
          }
        );

        this.tutorialName = tutorialName || null;
      }
    });

    this.tutorialConfig = this.getDefaultTutorialConfig();

    this.tutorial$ = new BehaviorSubject(this.tutorialConfig);
  }

  public canOpen(): boolean {
    if (this.tutorialName === 'seatSelection') {
      return this.dvmService?.viewer?.isLoaded?.();
    }

    if (this.tutorialName === 'checkout') {
      return true;
    }

    return false;
  }

  public isOpen() {
    return this.tutorialConfig.isOpen;
  }

  public getTutorialSectionAvailability() {
    return this.tutorialConfig.sectionAvailability;
  }

  public getTutorialSectionAvailabilityIds() {
    return this.tutorialConfig.sectionAvailabilityIds;
  }

  public getTutorialSeatsAvailability() {
    return this.tutorialConfig.seatsAvailability;
  }

  public getTutorialSeatsAvailabilityIds() {
    return this.tutorialConfig.seatsAvailabilityIds;
  }

  public getTutorialSelectedSeatNodes() {
    return this.tutorialConfig.seatNodes.selected;
  }

  public openTutorialForNewUser(event: SaleEvent) {
    // In the context of private selling, we do not want
    // to show the tutorial until the user has been succesfully
    // authenticated.
    if (
      event?.isPrivateSellingEnabled &&
      !this.privateSellingService.isUserInWhitelist()
    ) {
      return;
    }

    if (this.tutorialName === 'checkout') {
      return;
    }

    const storageKey = 'mmc_tutorial_viewed_state';

    const storageRawValue = localStorage.getItem(storageKey);
    const storageValue: Storage = storageRawValue
      ? JSON.parse(storageRawValue)
      : null;

    if (!storageValue || !storageValue[this.tutorialName]?.alreadyVisited) {
      const newStorage: Storage = {
        ...(storageValue ? storageValue : {}),
        [this.tutorialName]: { alreadyVisited: true },
      };

      localStorage.setItem(storageKey, JSON.stringify(newStorage));

      this.openTutorial();
    }
  }

  public openTutorial() {
    const modalData = {
      acceptFunction: this.startTutorial.bind(this),
    };

    if (this.tutorialName === 'seatSelection') {
      const intervalId = setInterval(() => {
        if (this.canOpen()) {
          clearInterval(intervalId);
          this.modalsService.openTutorialWelcomeModal(modalData);
        }
      }, 1000);
    }

    if (this.tutorialName === 'checkout' && this.canOpen()) {
      this.startTutorial();
    }
  }

  private getDefaultTutorialConfig() {
    const TutorialMocksConfig: TutorialMocksConfig = {
      sectionAvailabilityIds: [] as Array<string>,
      availableSeatsQuantity: 0,
      selectedSeatsQuantity: 0,
    };

    return {
      ...TutorialMocksConfig,
      isOnMapTopView: true,
      isOpen: false,
      isTutorialEnded: false,
      activeStepCommand: null as ActiveStepCommand,
      activeStep: {} as ReturnType<Driver['getActiveStep']>,
      selectedSectionNode: {} as MapViewerNode,
      sectionAvailability: {} as GeneralAvailabilityDict,
      selectedSectionIds: [] as Array<string>,
      selectedSectionSeatNodes: [] as Array<MapViewerNode>,
      seatsAvailability: {} as SeatAvailabilitySectionDict,
      seatsAvailabilityIds: [] as Array<string>,
      seatNodes: { available: [], selected: [] } as {
        available: Array<MapViewerNode>;
        selected: Array<MapViewerNode>;
      },
    };
  }

  private startTutorial() {
    if (!this.tutorialName) {
      return;
    }

    this.setTutorialMockDataOnMap();

    const tutorialData =
      this.configurationService.configuration.tutorial[this.tutorialName];

    const clientConfig = this.utilitiesService.isDesktopDevice
      ? tutorialData.desktop
      : tutorialData.mobile;

    this.tutorialConfig = {
      ...this.getDefaultTutorialConfig(),
      sectionAvailabilityIds: clientConfig.sectionAvailabilityIds,
      availableSeatsQuantity: clientConfig.availableSeatsQuantity,
      selectedSeatsQuantity: clientConfig.selectedSeatsQuantity,
    };

    const driverConfig: Config = {
      allowClose: false,
      doneBtnText: 'End Tutorial',
      nextBtnText: 'Next',
      popoverClass: 'driverjs-theme',
      prevBtnText: 'Back',
      ...clientConfig,
      onDestroyed: this.endTutorial.bind(this),
      onPrevClick: this.onPrevClick.bind(this),
      onNextClick: this.onNextClick.bind(this),
      steps: clientConfig.steps.map((step, index) => {
        const isFirstStep = index === 0;
        const isLastStep = index === clientConfig.steps.length - 1;
        return {
          ...step,
          popover: {
            ...step.popover,
            onPrevClick: isFirstStep
              ? this.openTutorialExitModal.bind(this)
              : this.onPrevClick.bind(this),
            onNextClick: isLastStep
              ? this.openTutorialExitModal.bind(this)
              : this.onNextClick.bind(this),
            ...(!isFirstStep && step.popover.onNextClickMethod
              ? { onNextClick: this[step.popover.onNextClickMethod].bind(this) }
              : {}),
            ...(!isLastStep && step.popover.onPrevClickMethod
              ? { onPrevClick: this[step.popover.onPrevClickMethod].bind(this) }
              : {}),
          },
        };
      }),
    };

    this.tutorial$.next(this.tutorialConfig);

    this.driver = driver(driverConfig);
    this.driver.drive();

    const activeStep = this.driver.getActiveStep();
    this.tutorialConfig = {
      ...this.tutorialConfig,
      isOpen: true,
      activeStep,
      activeStepCommand: this.getActiveStepCommand(activeStep),
    };
    this.tutorial$.next(this.tutorialConfig);
  }

  private endTutorial() {
    this.driver.destroy();

    // clears the cart an reload he map on the SeatManagementService
    this.tutorialConfig = { ...this.tutorialConfig, isTutorialEnded: true };
    this.tutorial$.next(this.tutorialConfig);

    // restores the default tutorial config
    this.tutorialConfig = this.getDefaultTutorialConfig();
    this.tutorial$.next(this.tutorialConfig);
  }

  private onNextClick() {
    this.setTutorialMockDataOnMap();
    this.getSelectedNode();

    this.driver.moveNext();

    const activeStep = this.driver.getActiveStep();
    this.tutorialConfig = {
      ...this.tutorialConfig,
      activeStep,
      activeStepCommand: this.getActiveStepCommand(activeStep),
    };
    this.tutorial$.next(this.tutorialConfig);
  }

  private onPrevClick() {
    this.setTutorialMockDataOnMap();

    this.driver.movePrevious();

    const activeStep = this.driver.getActiveStep();
    this.tutorialConfig = {
      ...this.tutorialConfig,
      activeStep,
      activeStepCommand: this.getActiveStepCommand(activeStep),
    };
    this.tutorial$.next(this.tutorialConfig);
  }

  private getActiveStepCommand(
    activeStep: ReturnType<Driver['getActiveStep']>
  ): ActiveStepCommand {
    return (activeStep as any)?.command || null;
  }

  private setTutorialMockDataOnMap() {
    if (this.tutorialName !== 'seatSelection') {
      return;
    }

    const isOnMapTopView = this.dvmService.isTopview;

    this.tutorialConfig.isOnMapTopView = isOnMapTopView;

    if (isOnMapTopView) {
      this.setTutorialMockData();

      // Set on map
      this.dvmService.viewer.setAvailability(
        'section',
        this.tutorialConfig.sectionAvailabilityIds
      );
      this.dvmService.viewer.setAvailability(
        'general_admission',
        this.tutorialConfig.sectionAvailabilityIds
      );
    } else {
      // Set on map
      this.dvmService.viewer.setAvailability(
        'seat',
        this.tutorialConfig.seatNodes.available
      );
      this.dvmService.viewer.select(this.tutorialConfig.seatNodes.selected);
    }

    this.tutorial$.next(this.tutorialConfig);
  }

  private setTutorialMockData() {
    if (this.tutorialName !== 'seatSelection') {
      return;
    }

    // TOP VIEW MOCKS
    this.tutorialConfig.sectionAvailability =
      TutorialMocks.getSectionAvailability(
        this.tutorialConfig.sectionAvailabilityIds,
        this.tutorialConfig.availableSeatsQuantity
      );

    this.tutorialConfig.selectedSectionIds = [
      this.tutorialConfig.sectionAvailabilityIds[0],
    ];

    if (!this.tutorialConfig.selectedSectionIds[0]) {
      return;
    }

    this.tutorialConfig.selectedSectionNode =
      this.dvmService.viewer.getNodeById(
        this.tutorialConfig.selectedSectionIds[0]
      );

    // SEAT MAP MOCK
    this.tutorialConfig.selectedSectionSeatNodes =
      this.dvmService.viewer.getNodesByParent(
        this.tutorialConfig.selectedSectionIds[0]
      );

    this.tutorialConfig.seatsAvailability = TutorialMocks.getSeatsAvailability(
      this.tutorialConfig.selectedSectionIds[0],
      this.tutorialConfig.selectedSectionSeatNodes
    );

    this.tutorialConfig.selectedSectionSeatNodes
      .reverse()
      .forEach((node, index) => {
        if (index < this.tutorialConfig.availableSeatsQuantity) {
          this.tutorialConfig.seatNodes.available.push(node);
        }

        if (index < this.tutorialConfig.selectedSeatsQuantity) {
          this.tutorialConfig.seatNodes.selected.push(node);
        }
      });

    this.tutorialConfig.seatsAvailabilityIds =
      this.tutorialConfig.seatNodes.available.map(v => v.id);
  }

  private openTutorialExitModal() {
    this.driver.moveNext();

    if (this.tutorialName === 'seatSelection') {
      const modalData = {
        title: `Tutorial complete!`,
        content: '',
        closeBtnName: 'Repeat',
        acceptBtnName: 'Buy Tickets',
        closeFunction: this.startTutorial.bind(this),
      };
      this.modalsService.openModal(modalData);
    }
  }

  private getSelectedNode() {
    const node = this.tutorialConfig.selectedSectionNode;

    return node;
  }

  private showSectionPopover() {
    this.setTutorialMockDataOnMap();

    this.dvmService.popoverPlacement.clear();

    const commponentRef = this.dvmService.popoverPlacement.createComponent(
      SectionPopoverComponent
    );

    commponentRef.instance.sectionData =
      this.tutorialConfig.sectionAvailability[
        this.tutorialConfig.selectedSectionNode.id
      ];
    commponentRef.instance.tooltipData.type = 'section';
    commponentRef.instance.sectionData.mmcID =
      this.tutorialConfig.selectedSectionNode.original_id;

    const tooltip: HTMLElement = document.querySelector('#tooltip');

    const node = this.getSelectedNode();

    const nodeDomCenter = this.dvmService.viewer.fromSceneToDom([
      node.aabb[0],
      node.aabb[1],
    ]);
    const nodeHalfHeight = node.aabb[3] / 2;
    const nodeVerticalCenter = nodeDomCenter[1] + nodeHalfHeight;
    const placement =
      nodeVerticalCenter >
      document.getElementById('seat-selection').offsetHeight / 2
        ? 'top'
        : 'bottom';

    const offset = node.type === 'seat' ? [0, 6] : [0, -10];

    createPopper(node, tooltip, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
      ],
    });

    tooltip.setAttribute('data-show', '');
  }

  private hidePopover() {
    const tooltip: HTMLElement = document.querySelector('#tooltip');
    if (tooltip) {
      tooltip.removeAttribute('data-show');
    }
  }

  /**
   * ACA COMIENZAN LOS TutorialMethods
   */

  public showSectionPopoverOnNextClick() {
    this.showSectionPopover();
    this.onNextClick();
  }

  public hidePopoverOnPrevClick() {
    this.hidePopover();
    this.onPrevClick();
  }

  public loadSeatMapOnNextClick() {
    this.hidePopover();
    this.dvmService
      .loadMap(this.tutorialConfig.selectedSectionNode.original_id)
      .then(() => {
        this.setTutorialMockDataOnMap();
        this.onNextClick();
      });
  }

  public showSectionPopoverAfterTopviewMapLoadOnPrevClick() {
    this.dvmService.loadMap().then(() => {
      this.showSectionPopover();
      this.onPrevClick();
    });
  }

  public loadTopViewMapOnNextClick() {
    this.dvmService.loadMap().then(() => {
      this.setTutorialMockDataOnMap();
      this.onNextClick();
    });
  }

  public loadSeatMapOnPrevClick() {
    this.hidePopover();
    this.dvmService
      .loadMap(this.tutorialConfig.selectedSectionNode.original_id)
      .then(() => {
        this.setTutorialMockDataOnMap();
        this.onPrevClick();
      });
  }
}
